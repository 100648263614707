import React from 'react';

const Header = ({ title }) => {
  return (
    <div className="header" style={{ height: '2.5%', padding: '10px', background: '#f0f2f5', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
      <h2 style={{ margin: '0' }}>{title}</h2>
    </div>
  );
};

export default Header;
