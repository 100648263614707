import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';
import { mapCategoryToColor } from '../helper/categoryColorMapping';

const SummaryStackedBarChart = ({ data, width, height, onGroupClick }) => {
  const svgRef = useRef();


  useEffect(() => {
    // Check if data is an array and has at least one item
    if (Array.isArray(data.summayData) && data.summayData.length > 0) drawChart();
  }, [data.summayData]);

  const drawChart = () => {
    const svg = d3.select(svgRef.current);
    svg.selectAll('*').remove();
    console.log('data.summayData for summary:', data.summayData)

    const margin = { top: 5, right: 15, bottom: 10, left: 50 };
    const chartWidthPercentage = [0.7,0.1,0.1,0.1]; // 50% of the width
    const height_constraint = height * 0.3
    const width_constraint = width * 0.3
    const donutChartHeight = d3.min([height_constraint, width_constraint]);
    const chartWidth = (width - margin.left - margin.right)*chartWidthPercentage[0] ; // here only have 50% for the stacked bar chart
    const chartHeight = height - margin.top - margin.bottom-donutChartHeight;



    // Calculate average for all
    // const allAverages = data.summayData.map(eachGroup => ({
    //   label: ld.label,
    //   averageCount: ld.average / individualCount
    // }));

    // Adjust the domain calculation to account for possibly empty data.summayData
    const xScale = d3.scaleLinear()
      .domain([0, d3.max(data.summayData, d => d.total) || 1]) // Ensure domain is at least 0 to 1
      .range([margin.left, chartWidth]);

    // Adjust yScale to handle empty data.summayData gracefully
    const yScale = d3.scaleBand()
      .domain(data.summayData.map(d => d.group))
      .range([margin.top, chartHeight])
      .padding(0.1);

  



      // Draw the grey bar chart
       // Define a function to calculate the maximum end of the bars
//  const calculateMaxEnd = (data, scale) => d3.max(data, d => scale(d));

//  let extraBarsStartX = calculateMaxEnd(series.flatMap(d => d.map(dd => dd[1])), xScale);


    //////////////////////
    const metricsNames = ['IG', 'RL', 'Score'];
    const metricColors = ['#cccccc', '#cccccc', '#888888']; // Set different shades of grey for each metric if required
   
    //////////////////////
     //////////////////////////////////////////////////
    // Create a pie chart for the group-level summary
    // Calculate summary data for the donut chart
    // Calculate the outer radius of the donut chart based on your chart dimensions
    const donutOuterRadius = donutChartHeight/3; // Adjust this as needed
    const donutInnerRadius = donutOuterRadius / 1.5; // Adjust this for the donut thickness you want

    // Positioning the donut chart
    const donutChartX = margin.left*0.25 + donutOuterRadius; // X position
    const donutChartY = margin.top + donutOuterRadius; // Y position

    // Pie generator for the donut chart
    const pie = d3.pie()
      .sort(null) // Do not sort, keep the original order
      .value(d => d.label_count); // Assuming your data has a 'label_count' property

    // Arc generator for the donut chart
    const arc = d3.arc()
      .innerRadius(donutInnerRadius)
      .outerRadius(donutOuterRadius);

    // Draw the donut chart
        // Ensure there's a tooltip div in your HTML, or create one
        // console.log('data.summaryDonutData:', data.summaryDonutData)
        const isStudent = data.summayData[0].student_or_task === "student";
const tooltipKeys = isStudent 
    ? ['average_cs_background', 'average_gpt_background', 'average_vis_background', 'avg_score']
    : ['average_task_difficulty', 'avg_score'];

const tooltip = d3.select('body').append('div')
.attr('class', 'tooltip')
.style('position', 'absolute')
.style('background-color', 'rgba(255, 255, 255, 0.8)')
.style('border', '1px solid #ddd')
.style('border-radius', '5px')
.style('padding', '10px')
.style('display', 'none')
.style('pointer-events', 'none'); // Ensure the tooltip doesn't interfere with mouse events

    const groupSummaryData = data.summaryDonutData; // Assuming this structure
    svg.append("g")
      .attr("transform", `translate(${donutChartX}, ${donutChartY})`)
      .selectAll("path")
      .data(pie(groupSummaryData))
      .enter()
      .append("path")
      .attr("d", arc)
      .attr("fill", d => mapCategoryToColor(d.data.label_category))
      .on('mouseover', function (event, d) {
        tooltip
          .style('display', 'inline-block')
          .style('left', `${event.pageX + 10}px`)
          .style('top', `${event.pageY + 10}px`)
          .html(() => {
            // Start the tooltip with a table
            let tooltipContent = '<table style="width:100%;border-collapse:collapse;text-align:left;">';
          
            // Add the header row for the keys (x-axis), leaving the first position empty
            tooltipContent += '<tr style="background-color:#f4f4f4;">'; // Add a background color to header row for better visibility
            tooltipContent += '<th style="border-bottom:1px solid #ddd;padding:5px;"></th>'; // First cell empty for alignment
            tooltipKeys.forEach(key => {
              const trimmedKey = key.replace('_', ' ').split(' ').slice(1).join(' '); // Remove the first word and replace underscores with spaces
              tooltipContent += `<th style="border-bottom:1px solid #ddd;padding:5px;text-align:center;">${trimmedKey}</th>`; // Center text alignment
            });
            tooltipContent += '</tr>';
          
            // Add a row for each group (y-axis)
            data.summayData.forEach((groupData, index) => {
              tooltipContent += `<tr>`;
              tooltipContent += `<td style="padding:5px;border-bottom:1px solid #ddd;font-weight:bold;background-color:#f9f9f9;">${groupData.group}</td>`; // Add background color for group name cell
              tooltipKeys.forEach(key => {
                let value = groupData[key];
                // Skip undefined values
                if (value !== undefined) {
                  // Format numbers to two decimal places
                  value = typeof value === 'number' ? value.toFixed(2) : value;
                  tooltipContent += `<td style="padding:5px;border-bottom:1px solid #ddd;text-align:center;">${value}</td>`; // Center text alignment in cells
                } else {
                  // Add an empty cell if value is undefined
                  tooltipContent += '<td style="padding:5px;border-bottom:1px solid #ddd;text-align:center;">N/A</td>';
                }
              });
              tooltipContent += '</tr>';
            });
          
            // Close the table tag
            tooltipContent += '</table>';
          
            return tooltipContent; // Return the HTML table
          });
          
          
          
          // .html(() => {
          //   // Construct the tooltip content
          //   const toolTipdata = data.summayData; // Data bound to this slice
          //   console.log('data here in summary:', data);
          //   const tooltipData = tooltipKeys.map(key => `<strong>${key.replace('_', ' ')}:</strong> ${toolTipdata[key].toFixed(2)}`);
          //   return `<div>${groupName}</div>${tooltipData.join('<br>')}`;
          // });
      })
      .on('mousemove', function (event) {
        tooltip
          .style('left', `${event.pageX + 10}px`)
          .style('top', `${event.pageY + 10}px`);
      })
      .on('mouseout', function () {
        tooltip.style('display', 'none');
      });

    let fontSize = 7.5;
    let textOffset = fontSize;
    // Add a label for the group name
    svg.append('text')
      .attr('transform', `translate(${donutChartX - textOffset}, ${donutChartY})`)
      .text("ALL")
      .attr('font-size', fontSize);

    //////////////////////////
    // add the metrics arcs
    //////////////////////////

    const metricValueScale = d3.scaleLinear()
      .domain([0, 1])
      .range([0, 2 * Math.PI]); // full circle

    // Draw grey arcs for metrics
    const arcMetrics = d3.arc()
      .innerRadius(donutInnerRadius * 1.1) // slightly outside the donut inner radius
      .outerRadius(donutOuterRadius * 1.1); // slightly outside the donut outer radius

    // Calculate metric arc lengths and draw them
    // Assuming you have a way to calculate the normalized metric values
    // Normalization scales for each metric
    const normalizeInfoGain = d3.scaleLinear()
      .domain([d3.min(data.summayData, d => d.avg_gpt_response_info_gain), d3.max(data.summayData, d => d.avg_gpt_response_info_gain)])
      .range([0, 1]);

    const normalizeResponseLength = d3.scaleLinear()
      .domain([d3.min(data.summayData, d => d.avg_normed_gpt_response_length), d3.max(data.summayData, d => d.avg_normed_gpt_response_length)])
      .range([0, 1]);

    const normalizeScore = d3.scaleLinear()
      .domain([d3.min(data.summayData, d => d.avg_score), d3.max(data.summayData, d => d.avg_score)])
      .range([0, 1]);

    const numGroups = data.summayData.length;

    // Function to draw metric arcs beside the donut chart
    const drawMetricArcs = (centerX, centerY, innerRadius, outerRadius) => {
      const arcWidth = innerRadius * 0.1; // Width of the metric arcs, adjust as needed
      const metricsArc = d3.arc()
        .innerRadius(innerRadius)
        .outerRadius(innerRadius + arcWidth);

      const metricsArcScore = d3.arc()
        .innerRadius(innerRadius - arcWidth*1.5)
        .outerRadius(innerRadius + arcWidth*1.5);
      const arcList = [metricsArc, metricsArc, metricsArcScore]
      // Assuming groupData has normalized values for these metrics
      let infoSum = 0;
      let totalItems = 0;
      for(let i=0; i<numGroups; i++){
        infoSum += data.summayData[i].avg_gpt_response_info_gain*data.summayData[i].numItems;
      }
      let responseLengthSum = 0;
      for(let i=0; i<numGroups; i++){
        responseLengthSum += data.summayData[i].avg_normed_gpt_response_length*data.summayData[i].numItems;
      }
      let scoreSum = 0;
      for(let i=0; i<numGroups; i++){
        scoreSum += data.summayData[i].avg_score*data.summayData[i].numItems;
      }
      for(let i=0; i<numGroups; i++){
        totalItems += data.summayData[i].numItems;
      }
      // console.log('totalItems:', totalItems);
      // console.log('numGroups:', numGroups);
      const groupMetrics = {
        infoGain: normalizeInfoGain( infoSum/totalItems),
        responseLength: normalizeResponseLength( responseLengthSum/totalItems),
        score: normalizeScore( scoreSum/totalItems)
      };
      // console.log('groupMetrics:', groupMetrics);

      const angles = {
        infoGain: metricValueScale(groupMetrics.infoGain),
        responseLength: metricValueScale(groupMetrics.responseLength),
        score: metricValueScale(groupMetrics.score)
      };

      function toTwoDecimals(floatNumber) {
        return floatNumber.toFixed(2);
      }
      // Draw the arcs for each metric
      ['infoGain', 'responseLength', 'score'].forEach((metric, i) => {
        let XOffset = arcWidth*4 + (innerRadius + arcWidth) * (i) * 2;
        svg.append('path')
          .datum({
            // startAngle: i * (2 * Math.PI / 3), // Each metric gets 1/3 of the circle
            // endAngle: i * (2 * Math.PI / 3) + angles[metric] // End angle based on the normalized value
            startAngle: 0, // Each metric gets 1/3 of the circle
            endAngle: angles[metric] // End angle based on the normalized value
          })
          .attr('d', arcList[i])
          .attr('transform', `translate(${centerX + XOffset}, ${centerY})`)
          .attr('fill', metricColors[i])

        svg.append('text')
          .attr('transform', `translate(${centerX + XOffset - 5}, ${centerY})`)
          .text(toTwoDecimals(groupMetrics[metric]))
          .attr('font-size', '7.5px');
      });
    };

    // After drawing the donut chart, call drawMetricArcs
    const donutCenterX = margin.left + donutOuterRadius; // Center of the donut chart on the x-axis
    const donutCenterY = margin.top + donutOuterRadius; // Center of the donut chart on the y-axis

    // Assuming the groupData has the required properties like avg_info_gain
    drawMetricArcs(donutCenterX, donutCenterY, donutInnerRadius, donutOuterRadius);

    const stackedBarOffset = 2 * donutCenterY;
    // Append groups and rects only if data.summayData is not empty
    if (data.summayData.length > 0) {
      svg.append('g')
        .selectAll('g')
        .data(data.summayData)
        .enter().append('g')
          .attr('transform', d => `translate(0, ${yScale(d.group)+stackedBarOffset})`)
        .selectAll('rect')
        .data(d => d.categories)
        .enter().append('rect')
          .attr('x', d => xScale(d.startValue))
          .attr('width', d => xScale(d.endValue) - xScale(d.startValue))
          .attr('height', yScale.bandwidth())
          .attr('fill', d => mapCategoryToColor(d.label))
          .on('click', (event, d) => {
            if (onGroupClick) {
                console.log('d.group:', d.group)
              // Ensure d.group is the correct identifier for your group
              // This might need adjustment based on your data structure
              onGroupClick(d.group);
            }
          });
        //   .on('click', (_, d) => onGroupClick(d.group));
    }

    // Add axes
    // svg.append('g')
    //   .attr('transform', `translate(0, ${chartHeight})`)
    //   .call(d3.axisBottom(xScale));

    svg.append('g')
      .attr('transform', `translate(${margin.left}, ${stackedBarOffset})`)
      .call(d3.axisLeft(yScale))
      .selectAll("text") // selects all text elements in the y-axis group
      .style("font-size", "7.5px"); // sets the font size of the y-axis labels to 10px

      //////////////////////////
      // draw the GPT metrics charts


    // New scales for GPT and Score metrics
    const xScaleGPTInfoGain = d3.scaleLinear()
    .domain([d3.min(data.summayData, d => d.avg_gpt_response_info_gain), d3.max(data.summayData, d => d.avg_gpt_response_info_gain)])
    .range([0, chartWidth * chartWidthPercentage[1]]); // 20% of the chartWidth for GPT info gain

  const xScaleGPTResponseLength = d3.scaleLinear()
    .domain([d3.min(data.summayData, d => d.avg_normed_gpt_response_length), d3.max(data.summayData, d => d.avg_normed_gpt_response_length)])
    .range([0, chartWidth * chartWidthPercentage[2]]); // 20% of the chartWidth for GPT response length

  const xScaleScore = d3.scaleLinear()
    .domain([d3.min(data.summayData, d => d.avg_score), d3.max(data.summayData, d => d.avg_score)])
    .range([0, chartWidth * chartWidthPercentage[3]]); // 20% of the chartWidth for average score


 

  // Calculate the starting x position for each set of metric bars
  const extraMetrics = ['avg_gpt_response_info_gain', 'avg_normed_gpt_response_length', 'avg_score'];

  const metricScales = [xScaleGPTInfoGain, xScaleGPTResponseLength, xScaleScore];
  // console.log('metricScales:', metricScales);

   const calculateMaxEnd = (data, scale) => d3.max(data, d => scale(d));

//  let extraBarsStartX = calculateMaxEnd(series.flatMap(d => d.map(dd => dd[1])), xScale);
let extraBarsStartX = chartWidth+10

  // Draw the extra metric bars and labels
  extraMetrics.forEach((metric, index) => {
    const metricScale = metricScales[index];
    // console.log("groupData.student_list",groupData)
    const metricData = data.summayData.map(d => d[metric]);

    // Calculate max width of the current metric's bars to adjust the starting position for the next metric
    const maxMetricWidth = calculateMaxEnd(metricData, metricScale);

    // Draw the bars for the current metric
    svg.selectAll(`.${metric}-bar`)
      .data(metricData)
      .enter()
      .append("rect")
      .attr("class", `${metric}-bar`)
      .attr("x", extraBarsStartX)
      .attr("y", (d, i) => yScale(data.summayData[i].group) + stackedBarOffset)
      .attr("width", metricScale)
      .attr("height", yScale.bandwidth())
      .attr("fill", metricColors[index]); // Set different shades of grey for each metric if required

    // Add labels for the first metric only
    // if (index === 0) {
    svg.append("text")
      .attr("x", extraBarsStartX + maxMetricWidth / 2 - 5)
      // .attr("y", margin.top - 10)
      .attr("y", donutChartHeight-margin.bottom) // Adjust the y position as needed
      // .text(metric.toUpperCase().replace(/_/g, ' '))
      .text(metricsNames[index])
      .attr("text-anchor", "middle")
      .style("font-size", "7.5px");
    // }

    // Update the starting x position for the next set of metric bars
    extraBarsStartX += maxMetricWidth + 10; // Add some space between different metrics


    ////////////////////////////////////////////////////////////////////////////
    // draw the donut chart's arcs here, to keep the size of each arc the same
  });
  };

  return (
    <svg ref={svgRef} width={width} height={height}></svg>
  );
};

export default SummaryStackedBarChart;
