// api.js
const API_BASE_URL = "http://zixinbackend.s58.hkustvis.org";

// Fetch student conversation data
export const fetchStudentConversation = (student_name, task_id) => {
  // Define the request options including method, headers, and body
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      student_name: student_name,
      task_id: task_id,
    }),
  };

  // Make the POST request to the backend API
  return fetch(`${API_BASE_URL}/api/get_student_conversation`, options).then(
    (response) => {
      if (!response.ok) {
        // If the server response was not ok, throw an error
        throw new Error("Network response was not ok");
      }
      // Parse and return the JSON response body
      return response.json();
    },
  );
};

// Fetch task description, task figure (if any), and student answer data
export const fetchTaskInfoStudentAnswer = (student_name, task_id) => {
  // Define the request options including method, headers, and body
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      task_id: task_id,
      student_name: student_name,
    }),
  };

  // Make the POST request to the backend API
  return fetch(`${API_BASE_URL}/api/task_information`, options).then(
    (response) => {
      if (!response.ok) {
        // If the server response was not ok, throw an error
        throw new Error("Network response was not ok");
      }
      // Parse and return the JSON response body
      return response.json();
    },
  );
};

// Fetch the filtered task data with distributions
export const fetchFilteredTaskData = (filters) => {
  const queryString = new URLSearchParams(filters).toString();
  return fetch(`${API_BASE_URL}/app/task_view/filtered?${queryString}`).then(
    (response) => {
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      return response.json();
    },
  );
};

// Fetch the filtered student data with distributions
export const fetchFilteredStudentData = (filters) => {
  const queryString = new URLSearchParams(filters).toString();
  return fetch(`${API_BASE_URL}/app/student_view/filtered?${queryString}`).then(
    (response) => {
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      return response.json();
    },
  );
};

// Fetch the tree data for the strategy view
export const fetchStrategyTreeData = (
  selected_task_list,
  selected_student_list,
) => {
  // Define the request options including method, headers, and body
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      selected_task_list: selected_task_list,
      selected_student_list: selected_student_list,
    }),
  };

  // Make the POST request to the backend API
  return fetch(`${API_BASE_URL}/app/process_data`, options).then((response) => {
    if (!response.ok) {
      // If the server response was not ok, throw an error
      throw new Error("Network response was not ok");
    }
    // Parse and return the JSON response body
    return response.json();
  });
};

// Fetch subsequence  data
export const fetchSubSequenceData = (
  selected_task_list,
  selected_student_list,
) => {
  // Define the request options including method, headers, and body
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      selected_task_list: selected_task_list,
      selected_student_list: selected_student_list,
    }),
  };

  // Make the POST request to the backend API
  return fetch(
    `${API_BASE_URL}/app/generate_frequent_subsequences`,
    options,
  ).then((response) => {
    if (!response.ok) {
      // If the server response was not ok, throw an error
      throw new Error("Network response was not ok");
    }
    // Parse and return the JSON response body
    return response.json();
  });
};

// Fetch sequence view data
export const fetchSequenceView = (selectedTaskList, selectedStudentGroup) => {
  // Define the request options including method, headers, and body
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      selected_task_list: selectedTaskList,
      selected_student_group: selectedStudentGroup,
    }),
  };

  // Make the POST request to the backend API
  return fetch(`${API_BASE_URL}/app/sequence_view`, options).then(
    (response) => {
      if (!response.ok) {
        // If the server response was not ok, throw an error
        throw new Error("Network response was not ok");
      }
      // Parse and return the JSON response body
      return response.json();
    },
  );
};

// Fetch the overall task_view data
export const fetchTaskView = () => {
  return fetch(`${API_BASE_URL}/app/task_view/overall`).then((response) => {
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    return response.json();
  });
};

// Fetch the overall task_view data
export const fetchStudentView = () => {
  return fetch(`${API_BASE_URL}/app/student_view/overall`).then((response) => {
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    return response.json();
  });
};

// Fetch the question distribution
export const fetchQuestionDistribution = () => {
  return fetch(`${API_BASE_URL}/api/questions/distribution`).then(
    (response) => {
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return response.json();
    },
  );
};

// Fetch the question list
// data source: /data/class0/raw_xlsx_files/question_list_summary.xlsx
// reference of list of category: "Comment" column of the above "question_list_summary.xlsx"
export const fetchQuestionListByCategory = (category) => {
  return fetch(`${API_BASE_URL}/api/questions/list/${category}`).then(
    (response) => {
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return response.json();
    },
  );
};

// Fetch all students' conversation data for a specific question. E.g., "Task1"
export const fetchConversationsForTask = (selectedQuestion) => {
  const header = { method: "GET", mode: "no-cors" };
  return fetch(
    `${API_BASE_URL}/api/conversations/${selectedQuestion}`,
    header,
  ).then((response) => {
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    return response.json();
  });
};

// Fetch the conversation data for a specific student and task
// "selectedQuestion" is similar with other "selectedQuestion" variable. The accepted input is like "Task1", "Task2", "Task3", ...
// "student_name" is the key value in student_data.json. E.g., student_name = "wong_mei_mei"
export const fetchIndividualConversationsForTask = (
  selectedQuestion,
  student_name,
) => {
  return fetch(
    `${API_BASE_URL}/api/conversations/${selectedQuestion}/${student_name}`,
  ).then((response) => {
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    return response.json();
  });
};

// Fetch the student list
export const fetchStudentList = () => {
  return fetch(`${API_BASE_URL}/api/students`).then((response) =>
    response.json(),
  );
};

// Fetch the specific student's details information (his/her answers for each question)
// here studentName is the key value in /data/class0/processed_json_files/student_data.json. E.g., studentName = "shen_juanya"
export const fetchStudentDetails = (studentName) => {
  return fetch(`${API_BASE_URL}/api/student/${studentName}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  }).then((response) => response.json());
};

// Fetch all students' action distribution data for the selected question
// List of selectedQuestion: ["Task1", "Task2", "Task3", "Task4", "Task5", ...]
// Here the parameter "selectedQuestion" is a single question. E.g., selectedQuestion = "Task2"
export const fetchActionDistribution = (selectedQuestion) => {
  return fetch(`${API_BASE_URL}/api/actions/distribution/${selectedQuestion}`, {
    method: "GET", // Correct method for fetching data
  }).then((response) => response.json());
};

// Fetch the action distribution data for a specific student and the selected question

// example of selectedStudentFileBase: "cai_qiaolong144103_question_827840_8624612_streamlit_download"
// ! please pay attention to the suffix of the "selectedStudentFileBase" parameter, whether it has the suffix of "_taskX" or ".txt"
// ! the suffix of the "selectedStudentFileBase" parameter may not be the same as the suffix of the "selectedStudent" parameter
// example of selectedQuestion: "Task2"
export const fetchIndividualActionDistribution = (
  selectedQuestion,
  selectedStudentFileBase,
) => {
  const queryParams = new URLSearchParams({
    selected_questions: selectedQuestion, // Assuming it's a single question now
    selected_student: selectedStudentFileBase,
  }).toString();
  return fetch(
    `${API_BASE_URL}/api/actions/individual_distribution?${queryParams}`,
    {
      method: "GET", // Correct method for fetching data
    },
  ).then((response) => response.json());
};

// Fetch the sub-sequence distribution for all students' data and the selected question
// here selectedQuestions is a list of selected questions. E.g., selectedQuestions = ["Task1", "Task2", "Task3", "Task4", "Task5", ...]
export const fetchOverallSequences = (selectedQuestions) => {
  const queryParams = new URLSearchParams({
    selected_questions: selectedQuestions,
  }).toString();
  return fetch(`${API_BASE_URL}/api/sequences/overall?${queryParams}`, {
    method: "GET",
  }).then((response) => response.json());
};

// Fetch the sub-sequence distribution for a specific student and the selected question

// example of selectedStudentFileBase: "cai_qiaolong144103_question_827840_8624612_streamlit_download"
// example of selectedQuestion: "Task2"
export const fetchIndividualSequences = (
  selectedQuestion,
  selectedStudentFileBase,
) => {
  const queryParams = new URLSearchParams({
    selected_question: selectedQuestion,
    selected_student: selectedStudentFileBase,
  }).toString();
  return fetch(`${API_BASE_URL}/api/sequences/individual?${queryParams}`, {
    method: "GET",
  }).then((response) => response.json());
};

// Fetch the word cloud data for the selected student and question
// example studentName is the key value under /data/class0/processed_json_files/student_data.json. E.g., studentName = "shen_juanya"
// example of selectedQuestion: "Task2"
export const generateWordCloudData = (studentName, selectedQuestion) => {
  const queryParams = new URLSearchParams({
    selected_student: studentName,
    selected_question: selectedQuestion,
  });
  return fetch(`${API_BASE_URL}/api/wordcloud?${queryParams}`, {
    method: "GET",
  }).then((response) => response.json());
};

// get the labelled conversation data for all students under a specific task
export const fetchFullConversationsWithLabels = (selectedTask) => {
  return fetch(
    `${API_BASE_URL}/api/labelled_conversations/${selectedTask}`,
  ).then((response) => {
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    return response.json();
  });
};

// get the labelled conversation data for a specific student and task
export const fetchIndividualConversationsWithLabels = (
  selectedTask,
  selectedStudentFileBase,
) => {
  return fetch(
    `${API_BASE_URL}/api/labelled_conversations/${selectedTask}/${selectedStudentFileBase}`,
  ).then((response) => {
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    return response.json();
  });
};

// get the strategy hierarchy data for a specific task
export const fetchStrategyHierarchy = (taskID) => {
  return fetch(`${API_BASE_URL}/api/strategy/hierarchy/${taskID}`).then(
    (response) => {
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return response.json();
    },
  );
};
