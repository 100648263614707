import * as React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";

export default function ConversationList(props) {
  const createConversationList = () =>
    props.conversationData
      .filter((d) => d.role !== "system")
      .map((d, i) => {
        if (d.role === "assistant") {
          return (
            <ListItem alignItems="flex-start" key={i}>
              <ListItemAvatar>
                <Avatar alt="System" src="/images/robot.jpeg" />
              </ListItemAvatar>
              <ListItemText primary={d.content} />
            </ListItem>
          );
        } else {
          return (
            <ListItem alignItems="flex-start" key={i}>
              <ListItemAvatar>
                <Avatar alt={props.student_name} src="" />
              </ListItemAvatar>
              <ListItemText primary={d.content} />
            </ListItem>
          );
        }
      });

  const ConversationListComponent = createConversationList();
  return (
    <List
      sx={{
        width: "100%",
        height: "100%",
        maxWidth: 360,
        bgcolor: "background.paper",
      }}
    >
      {ConversationListComponent}
    </List>
  );
}
