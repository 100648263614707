import React, { useState, useEffect, useRef } from 'react';
import * as d3 from 'd3';
import { fetchFilteredStudentData } from '../api/api';
import Header from './Header';
import {slider_snap,slider_cate,slider} from '../charts/createSlider';
import {BarChart,histogram} from '../charts/createDistributionCharts';
import 'd3-selection';
import 'd3-transition';
import 'd3-scale';
import { Button } from 'antd';
// import 'react-tabulator/css/bootstrap/tabulator_bootstrap.min.css';
// import 'react-tabulator/lib/styles.css';
// import { ReactTabulator } from 'react-tabulator'

// student metrics: average_score, vis_background, cs_backgroun (could be put in the same row),  gpt_background

const barChartMargin = { top: 25, right: 20, bottom: 20, left: 20 };
const histMargin = { top: 25, right: 20, bottom: 20, left: 20 };
const sliderMargin = { top: 5, right: 20, bottom: 25, left: 20 };
const sampleWidth = 300;
const barChartSize = {
  width: sampleWidth, 
  height: 75 
}
const histSize = {
  width: sampleWidth, 
  height: 100 
}
const sliderSize = {
  width: sampleWidth,
  height: 45
}
const visBarConfig = {
  chartTitle: "Visualization Background",
  x: d => d.category,
  y: d => d.count,
  // xDomain: d3.groupSort(alphabet, ([d]) => -d.frequency, d => d.letter), // sort by descending frequency
  // yFormat: "%",
  yLabel: "Count",
  width:barChartSize.width,
  height: barChartSize.height,
  marginTop:barChartMargin.top,
  marginBottom:barChartMargin.bottom,
  color: "#aaaaaa"
}

const csBarConfig = {
  chartTitle: "CS Background",
  x: d => d.category,
  y: d => d.count,
  // xDomain: d3.groupSort(alphabet, ([d]) => -d.frequency, d => d.letter), // sort by descending frequency
  // yFormat: "%",
  yLabel: "Count",
  width:barChartSize.width,
  height: barChartSize.height,
  marginTop:barChartMargin.top,
  marginBottom:barChartMargin.bottom,
  color: "#aaaaaa"
}

const gptBarConfig = {
  chartTitle: "ChatGPT Background",
  x: d => d.category,
  y: d => d.count,
  // xDomain: d3.groupSort(alphabet, ([d]) => -d.frequency, d => d.letter), // sort by descending frequency
  // yFormat: "%",
  yLabel: "Count",
  width:barChartSize.width,
  height: barChartSize.height,
  marginTop:barChartMargin.top,
  marginBottom:barChartMargin.bottom,
  color: "#aaaaaa"
}

const avgHisConfig = {
  chartTitle: "Average Score Distribution",

  width:histSize.width,
  height: histSize.height,
  marginTop:histMargin.top,
  marginBottom:histMargin.bottom,
  marginLeft: histMargin.left,
  color: "#888888"
}

const visSliderLayout = {
  width: sliderSize.width,
  height: sliderSize.height,
  margin: {
      top: sliderMargin.top,
      bottom: sliderMargin.bottom,
      left: sliderMargin.left,
      right: sliderMargin.right
  }
}

const csSliderLayout = {
  width: sliderSize.width,
  height: sliderSize.height,
  margin: {
    top: sliderMargin.top,
    bottom: sliderMargin.bottom,
    left: sliderMargin.left,
    right: sliderMargin.right
  }
}

const gptSliderLayout = {
  width: sliderSize.width,
  height: sliderSize.height,
  margin: {
    top: sliderMargin.top,
    bottom: sliderMargin.bottom,
    left: sliderMargin.left,
    right: sliderMargin.right
  }
}

const avgSliderLayout = {
  width: sliderSize.width,
  height: sliderSize.height,
  margin: {
    top: sliderMargin.top,
    bottom: sliderMargin.bottom,
    left: sliderMargin.left,
    right: sliderMargin.right
  }
}




function ActionView(props) {
  const [criteria, setCriteria] = useState({
    vis_range: [0, 1],  // Use an array to represent the range
    cs_range: [0, 1],  
    gpt_range: [0, 1], 
    average_score_range: [0, 1],
    name: '', // consider if have zone.

  });
  const [distributionData, setDistributionData] = useState({
    vis_distribution: [],
    cs_distribution: [],
    gpt_distribution: [],
    score_distribution: [],
  });

  const [retrievedStudents, setretrievedStudents] = useState([]);
  // Refs for the SVG containers
  const visDisRef = useRef(null);
  const visRef = useRef(null);

  const csDisRef = useRef(null);
  const csRef = useRef(null);

  const gptDisRef = useRef(null);
  const gptRef = useRef(null);

  const averageScoreDisRef = useRef(null);
  const averageScoreRef = useRef(null);

 

  useEffect(() => {
    if (visRef.current) { // if not null
      slider_snap(visSliderLayout,visRef.current,  criteria.vis_range, criteria.vis_range,'vis_range',setCriteria);
    }
  }, [criteria.vis_range]); // Depend specifically on vis_range

  useEffect(() => {
    if (csRef.current) { // if not null
      slider_snap(csSliderLayout,csRef.current,  criteria.cs_range, criteria.cs_range,'cs_range',setCriteria);
    }
  }, [criteria.cs_range]); // Depend specifically on vis_range

  useEffect(() => {
    if (gptRef.current) { // if not null
      slider_snap(gptSliderLayout,gptRef.current,  criteria.gpt_range, criteria.gpt_range,'gpt_range',setCriteria);
    }
  }, [criteria.gpt_range]); // Depend specifically on vis_range


  useEffect(() => {
    if (averageScoreRef.current) { // if not null
      slider(avgSliderLayout,averageScoreRef.current,  criteria.average_score_range, criteria.average_score_range,'average_score_range',setCriteria);
    }
  }, [criteria.average_score_range]); // Depend specifically on average_score_range
 






  useEffect(() => {
    const initializeData = () => {
      const filters = {
        vis_range: criteria.vis_range.join(','),
        cs_range: criteria.cs_range.join(','),
        gpt_range: criteria.gpt_range.join(','),
        average_score_range: criteria.average_score_range.join(','),
        
        name: criteria.name,
 
      };
      fetchFilteredStudentData(filters)
        .then((data) => {
          console.log('Retrieved Initial Student data:', data);
          // Check if students are different to prevent unnecessary state updates
          if (JSON.stringify(retrievedStudents) !== JSON.stringify(data.filtered_students)) {
            const students = data.filtered_students || []; // Fallback to an empty array if undefined
            setretrievedStudents(students);

            props.handleSelectedStudentGroups(students) // pass the selected students to the strategy view
          }
          // Check if distributions are different to prevent unnecessary state updates
          const newDistributionData = {
            cs_distribution: data.cs_distribution,
            vis_distribution: data.vis_distribution,
            gpt_distribution: data.gpt_distribution,
            score_distribution: data.score_distribution,
          };
          if (JSON.stringify(distributionData) !== JSON.stringify(newDistributionData)) {
            setDistributionData(newDistributionData);
          }
          // draw the initial distribution
          if (visDisRef.current) { // if not null, can get the dom element from the ref
            BarChart(visDisRef.current,  newDistributionData.vis_distribution
              , visBarConfig);
          }
          if (csDisRef.current) { // if not null, can get the dom element from the ref
            BarChart(csDisRef.current,  newDistributionData.cs_distribution
              , csBarConfig);
          }
          if (gptDisRef.current) { // if not null, can get the dom element from the ref
            BarChart(gptDisRef.current,  newDistributionData.gpt_distribution
              , gptBarConfig);
          }
          if (averageScoreDisRef.current) { // if not null, can get the dom element from the ref
            histogram(averageScoreDisRef.current,  newDistributionData.score_distribution
              , avgHisConfig);
          }
          


        })
        .catch((error) => {
          console.error('Error fetching students:', error);
          setretrievedStudents([]);
        });
    };

    initializeData();
    // Convert criteria object to a string to ensure stable dependency
  }, [JSON.stringify(criteria)]); // Convert criteria object to JSON string to stabilize dependency


  const retrieveStudents = () => {
    const filters = {
      cs_range: criteria.cs_range.join(','),
      vis_range: criteria.vis_range.join(','),
      gpt_range: criteria.gpt_range.join(','),
      average_score_range: criteria.average_score_range.join(','),
      name: criteria.name,

    };
    fetchFilteredStudentData(filters)
      .then((data) => {
        console.log('Retrieved data:', data);
        const students = data.filtered_students || []; // Ensure it falls back to an empty array
        console.log('Retrieved students:', students);
        setretrievedStudents(students);
        setDistributionData(data);
        props.handleSelectedStudentGroups(students) // pass the selected students to the strategy view
        
        // if (visDisRef.current) { // if not null, can get the dom element from the ref
        //   BarChart(visDisRef.current,  data.vis_distribution
        //     , visBarConfig);
        // }
        // if (csDisRef.current) { // if not null, can get the dom element from the ref
        //   BarChart(csDisRef.current,  data.cs_distribution
        //     , csBarConfig);
        // }

      })
      .catch((error) => {
        console.error('Error fetching students:', error);
        setretrievedStudents([]); // Ensure it falls back to an empty array on error
      });
  };


  return (
    <div className="action-view">
      <Header title="Student Overview" />
   
      <div className="body-content">
        <svg ref={visDisRef} className="distribution-container"></svg>
        <svg ref={visRef} className="slider-container"></svg>
        <svg ref={csDisRef} className="distribution-container"></svg>
        <svg ref={csRef} className="slider-container"></svg>
        <svg ref={gptDisRef} className="distribution-container"></svg>
        <svg ref={gptRef} className="slider-container"></svg>
        <svg ref={averageScoreDisRef} className="distribution-container"></svg>
        <svg ref={averageScoreRef} className="slider-container"></svg>


        {/* <div className="retrieval-button-container">

          <Button type="primary" size="small" onClick={retrieveStudents}>
          Retrieve
          </Button>
        </div> */}


      </div>
    </div>
  );
}

export default ActionView;
