import React, { useEffect, useState } from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import {
  fetchConversationsForTask,
  fetchIndividualConversationsForTask,
} from "../api/api"; // Adjusted import
import ConversationList from "../charts/createConversationList";
import { create } from "@mui/material/styles/createTransitions";

function ConversationView(props) {
  const [selectedStudentName, setSelectedStudentName] = useState(null);
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const [conversationByStudents, setConversationByStudents] = useState(null);
  const [conversationBySelectedStudent, setConversationBySelectedStudent] =
    useState(null);

  useEffect(() => {
    setSelectedStudentName(props.selectedStudentName);
    setSelectedQuestion(props.selectedQuestion);

    if (props.selectedQuestion !== null && props.selectedStudentName === null) {
      fetchConversationsForTask(
        props.selectedQuestion.toLowerCase(),
      ).then((data) => {
        setConversationByStudents(data);
      });
    } else if (
      props.selectedQuestion !== null &&
      props.selectedStudentName !== null
    ) {
      fetchIndividualConversationsForTask(
        props.selectedQuestion.toLowerCase(),
        props.selectedStudentName,
      ).then((data) => {
        setConversationBySelectedStudent(data);
      });
    }
  }, [props]);

  function createConversationComponentByStudents() {
    if (conversationByStudents === null) {
      return <></>;
    } else {
      return (
        <div>
          {conversationByStudents.map((d, i) => (
            <ConversationList
              key={i}
              conversationData={d.student_conversation}
              studentName={d.student_name}
            />
          ))}
        </div>
      );
    }
  }

  function createConversationComponentBySelectedStudent() {
    if (conversationBySelectedStudent === null) {
      return <></>;
    } else {
      return (
        <ConversationList
          conversationData={conversationBySelectedStudent.student_conversation}
          studentName={conversationBySelectedStudent.student_name}
        />
      );
    }
  }

  const conversationComponentByStudents =
    createConversationComponentByStudents();
  const conversationComponentBySelectedStudent =
    createConversationComponentBySelectedStudent();

  return (
    <div className="conversation-view">
      {/* {selectedStudentName === null && conversationComponentByStudents} */}
      Conversation data by{" "}
      {selectedStudentName === null && conversationByStudents
        ? conversationByStudents.length
        : 0}{" "}
      students
      {selectedStudentName !== null && conversationComponentBySelectedStudent}
    </div>
  );
}

export default ConversationView;
