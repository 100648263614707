const CategoryColorMapping = {
    "Remember": "#FFD580 ",
    "Understand": "#FFB347",
    "Apply": "#FF8C00",
    "Analyze": "#E67300",
    "Evaluate": "#CC6600",
    "Create": "#995C00", 
    "General prompt strategies from literature": "#779ECB",
    "Others": "#A0DAA9"

   
};

/**
 * Function to map a label to its category.
 * @param {string} label - The label to find the category for.
 * @returns {string} The category code corresponding to the label.
 */
function mapCategoryToColor(label) {
    // Return the category code if found, or a default value (e.g., 'Unknown') if not
    return CategoryColorMapping[label] || 'Unknown';
}

// Export the function for use in other files
module.exports = {
     mapCategoryToColor
};