import React, { useEffect, useState, useRef } from 'react';
import { fetchFilteredTaskData } from '../api/api';
import {slider_snap,slider_cate,slider} from '../charts/createSlider';
import {BarChart,histogram} from '../charts/createDistributionCharts';
import Header from './Header';
import * as d3 from 'd3';
import 'd3-selection';
import 'd3-transition';
import 'd3-scale';
import { Button } from 'antd';
// import { use } from 'echarts';

const barChartMargin = { top: 25, right: 20, bottom: 20, left: 20 };
const histMargin = { top: 25, right: 20, bottom: 20, left: 30 };
const sliderMargin = { top: 5, right: 20, bottom: 25, left: 20 };
const sampleWidth = 250;
const barChartSize = {
  width: sampleWidth,
  height: 75 
}
const histSize = {
  width: sampleWidth,
  height: 100 
}
const sliderSize = {
  width: sampleWidth,
  height: 45
}
const diffBarConfig = {
  chartTitle: "Difficulty Distribution",
  x: d => d.category,
  y: d => d.count,
  // xDomain: d3.groupSort(alphabet, ([d]) => -d.frequency, d => d.letter), // sort by descending frequency
  // yFormat: "%",
  yLabel: "Count",
  width:barChartSize.width,
  height: barChartSize.height,
  marginTop:barChartMargin.top,
  marginBottom:barChartMargin.bottom,
  color: "#aaaaaa"
}

const typeBarConfig = {
  chartTitle: "Question Type Distribution",
  x: d => d.category,
  y: d => d.count,
  // xDomain: d3.groupSort(alphabet, ([d]) => -d.frequency, d => d.letter), // sort by descending frequency
  // yFormat: "%",
  labelFontSize: 6.5,
  yLabel: "Count",
  xDomain:['self_learning','apply','understand' ,'analyze','create' ,'evaluation','remember'],
  width:barChartSize.width,
  height: barChartSize.height,
  marginTop:barChartMargin.top,
  marginBottom:barChartMargin.bottom,
  color: "#aaaaaa"
}

const avgHisConfig = {
  chartTitle: "Average Score Distribution",

  width:histSize.width,
  height: histSize.height,
  marginTop:histMargin.top,
  marginBottom:histMargin.bottom,
  marginLeft: histMargin.left,
  labelFontSize: 6.5,
  color: "#888888"
}

const diffSliderLayout = {
  width: sliderSize.width,
  height: sliderSize.height,
  margin: {
      top: sliderMargin.top,
      bottom: sliderMargin.bottom,
      left: sliderMargin.left,
      right: sliderMargin.right
  }
}

const typeSliderLayout = {
  width: sliderSize.width,
  height: sliderSize.height,
  margin: {
    top: sliderMargin.top,
    bottom: sliderMargin.bottom,
    left: sliderMargin.left,
    right: sliderMargin.right
  }
}

const avgSliderLayout = {
  width: sliderSize.width,
  height: sliderSize.height,
  margin: {
    top: sliderMargin.top,
    bottom: sliderMargin.bottom,
    left: sliderMargin.left,
    right: sliderMargin.right
  }
}

function QuestionView(props) {
  const [criteria, setCriteria] = useState({
    difficulty_range: [0, 6],  // Use an array to represent the range
    average_score_range: [0, 1],
    keyword: '',
    type_list: ["self_learning","apply","understand" ,"analyze","create" ,"evaluation","remember"],
  });
  const [distributionData, setDistributionData] = useState({
    difficulty_distribution: [],
    score_distribution: [],
    type_distribution: [],
  });
  const [typeOrderList, setTypeOrderList] = useState([]); // ['self_learning','apply','understand' ,'analyze','create' ,'evaluate','remember'

  const [retrievedTasks, setRetrievedTasks] = useState([]);
  // Refs for the SVG containers
  const difficultyDisRef = useRef(null);
  const difficultyRef = useRef(null);

  const averageScoreDisRef = useRef(null);
  const averageScoreRef = useRef(null);

  const typeDisRef = useRef(null);
  const typeRef = useRef(null);

  // State variables for chart dimensions
  const [chartDimensions, setChartDimensions] = useState({
    barChartWidth: barChartSize.width,
    histChartWidth: histSize.width,
    sliderWidth: sliderSize.width
  });

  // Ref for the chart container
  const chartContainerRef = useRef(null);

  // ResizeObserver to update chart dimensions
  useEffect(() => {
    const resizeObserver = new ResizeObserver(entries => {
      for (let entry of entries) {
        setChartDimensions({
          barChartWidth: entry.contentRect.width,  // You might want to subtract any padding or margins
          histChartWidth: entry.contentRect.width, // Same as above
          sliderWidth: entry.contentRect.width     // Same as above
        });
      }
    });

    if (chartContainerRef.current) {
      resizeObserver.observe(chartContainerRef.current);
    }

    return () => {
      if (chartContainerRef.current) {
        resizeObserver.unobserve(chartContainerRef.current);
      }
    };
  }, []);

  //////////////////////////
  useEffect(() => {
    if (difficultyRef.current) { // if not null
      slider_snap(diffSliderLayout,difficultyRef.current,  criteria.difficulty_range, criteria.difficulty_range,'difficulty_range',setCriteria);
    }
  }, [criteria.difficulty_range]); // Depend specifically on difficulty_range



  useEffect(() => {
    if (averageScoreRef.current) { // if not null
      slider(avgSliderLayout,averageScoreRef.current,  criteria.average_score_range, criteria.average_score_range,'average_score_range',setCriteria);
    }
  }, [criteria.average_score_range]); // Depend specifically on average_score_range
 
  useEffect(() => {
    if (typeRef.current) { // if not null
      slider_cate(typeSliderLayout,typeRef.current,  criteria.type_list,[1,7],[1,7],'type_list',setCriteria);
    }
  }, [criteria.type_list]); // Depend specifically on type_list





  useEffect(() => {
    const initializeData = () => {
      const filters = {
        difficulty_range: criteria.difficulty_range.join(','),
        average_score_range: criteria.average_score_range.join(','),
        keyword: criteria.keyword,
        type_list: criteria.type_list,
      };
      fetchFilteredTaskData(filters)
        .then((data) => {
          console.log('Retrieved Initial data:', data);
          // Check if tasks are different to prevent unnecessary state updates
          if (JSON.stringify(retrievedTasks) !== JSON.stringify(data.filtered_tasks)) {
            const tasks = data.filtered_tasks || []; // Fallback to an empty array if undefined
            setRetrievedTasks(tasks);

            props.handleSelectedTaskGroups(tasks) // pass the selected tasks to the strategy view
          }
          // Check if distributions are different to prevent unnecessary state updates
          const newDistributionData = {
            difficulty_distribution: data.difficulty_distribution,
            score_distribution: data.score_distribution,
            type_distribution: data.type_distribution,
          };
          // // This function extracts the 'category' from each element in the distribution data to form an order.
          // function getTypeOrder(distributionData) {
          //   return distributionData.map(item => item.category);
          // }


          // // console.log('Retrieved Initial type_distribution :', data.type_distribution);
          // let type_order = getTypeOrder(data.type_distribution);
          // console.log('Retrieved Initial type_order :', type_order);
          // setTypeOrderList(type_order);
          // setCriteria(
          //   {
          //     difficulty_range: [0, 6],  // Use an array to represent the range
          //     average_score_range: [0, 1],
          //     keyword: '',
          //     type_list: type_order,
          //   }
          // )
          if (JSON.stringify(distributionData) !== JSON.stringify(newDistributionData)) {
            setDistributionData(newDistributionData);
          }
          // draw the initial distribution
          if (difficultyDisRef.current) { // if not null, can get the dom element from the ref
            BarChart(difficultyDisRef.current,  newDistributionData.difficulty_distribution
              , diffBarConfig);
          }
          if (averageScoreDisRef.current) { // if not null, can get the dom element from the ref
            histogram(averageScoreDisRef.current,  newDistributionData.score_distribution
              , avgHisConfig);
          }
          if (typeDisRef.current) { // if not null, can get the dom element from the ref
            BarChart(typeDisRef.current,  newDistributionData.type_distribution
              , typeBarConfig);
          }
          // send the selected tasks to the strategy view


        })
        .catch((error) => {
          console.error('Error fetching tasks:', error);
          setRetrievedTasks([]);
        });
    };

    initializeData();
    // Convert criteria object to a string to ensure stable dependency
  }, [JSON.stringify(criteria)]); // Convert criteria object to JSON string to stabilize dependency


  const retrieveTasks = () => {
    const filters = {
      difficulty_range: criteria.difficulty_range.join(','),
      average_score_range: criteria.average_score_range.join(','),
      keyword: criteria.keyword,
      type_list: criteria.type_list,
    };
    fetchFilteredTaskData(filters)
      .then((data) => {
        console.log('Retrieved data:', data);
        const tasks = data.filtered_tasks || []; // Ensure it falls back to an empty array
        console.log('Retrieved tasks:', tasks);
        setRetrievedTasks(tasks);
        setDistributionData(data);
        props.handleSelectedTaskGroups(tasks) // pass the selected tasks to the strategy view
        // if (difficultyDisRef.current) { // if not null, can get the dom element from the ref
        //   BarChart(difficultyDisRef.current,  data.difficulty_distribution
        //     , diffBarConfig);
        // }
        // if (typeDisRef.current) { // if not null, can get the dom element from the ref
        //   BarChart(typeDisRef.current,  data.type_distribution
        //     , typeBarConfig);
        // }

      })
      .catch((error) => {
        console.error('Error fetching tasks:', error);
        setRetrievedTasks([]); // Ensure it falls back to an empty array on error
      });
  };




  // const handleInputChange = (event) => {
  //   const { name, value } = event.target;
  //   setCriteria((prev) => ({ ...prev, [name]: value }));
  // };

  // const resetInput = (name) => {
  //   const defaults = { difficulty_range: '0,10', average_score_range: '0,100', keyword: '', type_list: '' };
  //   setCriteria(prev => ({ ...prev, [name]: defaults[name] }));
  // };




  // // Handler to remove a group tag
  // const handleRemoveTag = (groupName) => {
  //   setRetrievedTasks(retrievedTasks.filter((task) => task.groupName !== groupName));
  // };

  return (
    <div className="question-view">
      {/* <div className="header-fixed"> */}
      <Header title="Task Overview" />
      {/* </div> */}
      <div className="body-content" ref={chartContainerRef}>
        <svg ref={difficultyDisRef} className="distribution-container"></svg>
        <svg ref={difficultyRef} className="slider-container"></svg>
        <svg ref={averageScoreDisRef} className="distribution-container"></svg>
        <svg ref={averageScoreRef} className="slider-container"></svg>
        <svg ref={typeDisRef} className="distribution-container"></svg>
        <svg ref={typeRef} className="slider-container"></svg>
        {/* <div className="input-container">
          <div className="input-with-button">
            <input
              className="input-field"
              name="keyword"
              type="text"
              value={criteria.keyword}
              placeholder="Keyword"
              onChange={handleInputChange}
            />
            <button className="reset-button" onClick={() => resetInput('keyword')}>Reset</button>
          </div>
        </div> */}
        {/* <div className="retrieval-button-container">
         
          <Button type="primary" size="small" onClick={retrieveTasks}>
          Retrieve
          </Button>
        </div> */}

        {/* <div className="retrieved-tasks-container">
          {retrievedTasks?.map((task, index) => (
            <div key={index}>
              <p>Task details</p>
            </div>
          ))}
        </div>
        <div className="retrieved-tags">
          {retrievedTasks.map((group, index) => (
            <div className="tag" key={index}>
              {group.groupName}
              <div className="tag-cross" onClick={() => handleRemoveTag(group.groupName)}>✕</div>
            </div>
          ))}
        </div> */}
      </div>
    </div>
  );
}

export default QuestionView;
