const labelCategoryMapping = {
    "Concept Comparison": "Analyze",
    "Application Inquiry": "Apply",
    "Hypothetical Questioning": "Create",
    "Learning Materials Creation": "Create",
    "Critiquing Questioning": "Evaluate",
    "Ask Sub/FollowUp-Questions": "Analyze",
    "Fact Verification": "General prompt strategies from literature",
    "Output Restriction": "General prompt strategies from literature",
    "Persona": "General prompt strategies from literature",
    "Provide Example": "General prompt strategies from literature",
    "Repeated Prompt": "General prompt strategies from literature",
    "Workflow Description": "General prompt strategies from literature",
    "Default System Prompt": "Others",
    "Empty": "Others",
    "GPT Feasiability Inquiry": "Others",
    "Use Other Resources": "Others",
    "Definition Inquiry": "Remember",
    "Learning Recommendation": "Remember",
    "Question Inquiry": "Remember",
    "Technique Inquiry": "Remember",
    "Elaboration with Example": "Understand",
    "Elaboration with Figure": "Understand",
    "General Elaboration": "Understand",
    "Purpose Inquiry": "Understand" 
};

/**
 * Function to map a label to its category.
 * @param {string} label - The label to find the category for.
 * @returns {string} The category code corresponding to the label.
 */
function mapLabelToCategory(label) {
    // Return the category code if found, or a default value (e.g., 'Unknown') if not
    return labelCategoryMapping[label] || 'Unknown';
}

// Export the function for use in other files
module.exports = {
    mapLabelToCategory
};