import * as d3 from 'd3';
const { mapCategoryToColor } = require('../helper/categoryColorMapping');
export function drawTree(data, checkedNodes, selectedStrategyIndex = null, props, handleStudentTagClick) {
    console.log("data in the tree",data)
    // const margin = { top: 50, right: 90, bottom: 30, left: 90 }, // 960,500
    //     width = 700 - margin.left - margin.right,
    //     height = 500 - margin.top - margin.bottom;
// Fetch the dimensions of the container
const container = d3.select('.tree').node().getBoundingClientRect();
// console.log("container",container)
// console.log("container.height",container.height)
console.log("container.width",container.width)

// Calculate the width and height based on the container dimensions and margins
const margin = { top: 5, right: 30, bottom: 30, left: 50 },
      width = container.width - margin.left - margin.right,
      height = container.height - margin.top - margin.bottom;

    const nodeTextColor = 'darkred'

    // Clear previous visualization
    d3.select('.tree > svg').remove();


    const svgContainer = d3.select('.tree').append('svg')
        .attr('width', width + margin.left + margin.right)
        .attr('height', height + margin.top + margin.bottom)
        .attr('id', 'svgCanvas'); // Adding an ID to the SVG canvas

    const svg = svgContainer.append('g')
        .attr('transform', `translate(${margin.left},${margin.top})`);

    svgContainer.on('click', function () {
        // Existing reset logic for opacity and text color
        svg.selectAll('.link, .node').style('opacity', 1)
            .selectAll('text') // Select all text elements within the matching node
            .style('fill', 'black'); // Change their text color to red ;
        svg.selectAll('.tempLink').remove(); // Remove any temporary links

        // New functionality: Iterate over all checkboxes and hide unchecked ones
        d3.selectAll('.tree svg .node input[type="checkbox"]').each(function () {
            const checkbox = d3.select(this);
            const isChecked = checkbox.property('checked');

            // If not checked, hide the parent node and checkbox
            if (!isChecked) {
                const parentNode = d3.select(this.parentNode.parentNode); // Adjust based on your actual DOM structure
                parentNode.style('display', 'none'); // Hide the node element
            }
        });
    });

////////////////////////////////////////////////////
// handle the visual clutter of the tree
// Define the zoom behavior
// const zoomBehavior = d3.zoom()
//   .scaleExtent([0.1, 10])
//   .on('zoom', (event) => {
//     const transform = event.transform;
//     svg.attr('transform', transform);

//     // Adjust node and font sizes based on the zoom level
//     // Here we're calculating the node size inversely proportional to the zoom level.
//     // The "+ 5" is a base size to ensure they don't disappear at high zoom levels, adjust as necessary.
//     const nodeSize = Math.max(5, 20 / transform.k);

//     svg.selectAll('.node circle')
//       .attr('r', nodeSize); // Adjust the radius of the nodes
    
//     svg.selectAll('.node text')
//       .style('font-size', `${Math.max(5, 12 / transform.k)}px`); // Adjust the font size
//   });

// svgContainer.call(zoomBehavior)
//   .on('dblclick.zoom', null);

//   function resetZoom() {
//     svg.transition()
//       .duration(750) // transition duration in milliseconds
//       .call(zoomBehavior.transform, d3.zoomIdentity); // reset zoom
//   }

//           d3.select('body').on('keypress', function(event) {
//             if (event.key === 'r') {
//               resetZoom();
//             }
//           });
          

//////////////////////////////////////////////////////////
//
// collapse the tree
const FIRST_THRESHOLD = 8; // The number of unique paths to trigger the first collapse
const SECOND_THRESHOLD = 12; // The number of unique paths to trigger the second collapse

function collapseChildrenExceptFirstTwo(node) {
    if (node.children && node.children.length > 2) {
      node._children = node.children; // Backup the children
      node.children = node._children.slice(0, 2); // Keep the first two children
      // Apply the collapse recursively to all children except the first two
      node._children.slice(2).forEach(collapseChildrenExceptFirstTwo);
    } else if (node.children) {
      node.children.forEach(collapseChildrenExceptFirstTwo); // Apply the collapse to each child if less than 2 children
    }
  }
  
function collapseChildrenExceptFirst(node) {
if (node.children && node.children.length > 1) {
    node._children = node.children; // Backup children nodes
    node.children = [node.children[0]]; // Retain the first child only
    node._children.slice(1).forEach(collapseChildrenExceptFirst); // Apply the same for other children
}
}

function clusterTreeIfNeeded(root) {
  const totalPaths = countUniquePaths(root);
  console.log('Total unique paths:', totalPaths);
  if (totalPaths > SECOND_THRESHOLD) {
    collapseChildrenExceptFirst(root);
  } else if (totalPaths > FIRST_THRESHOLD) {
    collapseChildrenExceptFirstTwo(root);
  }
}

/// 
// const MAX_PATHS = 5; // Maximum number of unique paths to display
// // Utility function to count all unique paths in the tree
function countUniquePaths(node, pathSet = new Set(), currentPath = '') {
    if (node.children && node.children.length > 0) {
      node.children.forEach((child) => {
        countUniquePaths(child, pathSet, `${currentPath}/${child.data.name}`);
      });
    } else {
      // We reached a leaf
      pathSet.add(currentPath);
    }
    return pathSet.size;
  }
  
//   // Function to collapse all children except for the first child recursively

  
//   // Main function to cluster the tree
//   function clusterTreeIfNeeded(root) {
//     const totalPaths = countUniquePaths(root);
//     console.log('Total unique paths:', totalPaths);
//     if (totalPaths > MAX_PATHS) {
//       collapseChildrenExceptFirst(root);
//       // Redraw the tree here after collapsing nodes
//     }
//   }

//   // Function to expand all nodes
// function expandAllNodes(node) {
//     if (node._children) {
//       node.children = node._children; // Restore the original children
//       node._children = null;
//     }
//     if (node.children) {
//       node.children.forEach(expandAllNodes); // Recursively expand all children
//     }
//   }
  
//   // Attach this function to a button or an event
//   d3.select('#expand-all-button').on('click', () => {
//     expandAllNodes(root);
//     // Redraw the tree here after expanding nodes
//   });




  

////////////////////////////////////////////////////
    const root = d3.hierarchy(data, d => d.children);
    clusterTreeIfNeeded(root); // This will modify the tree structure if needed

    const treeHeight = root.height + 1;
    // console.log("selectedStrategyIndex",selectedStrategyIndex)

    function highlightPaths(root, selectedStrategyIndex) {
        if (selectedStrategyIndex === null) return; // no highlight when no strategy is selected
        root.descendants().forEach(node => {
            // console.log("selectedStrategyIndex",selectedStrategyIndex)
            // console.log("node.data.strategies",node.data.strategies)
            const hasStrategies = Array.isArray(node.data.strategies);
            
            const isSelectedStrategy = hasStrategies && node.data.strategies.includes(selectedStrategyIndex);
            console.log("isSelectedStrategy",isSelectedStrategy)
            const opacity = isSelectedStrategy ? 1 : 0.2; // Full opacity for selected, 50% for others
            d3.select(`#node-${node.data.id}`).style('opacity', opacity);
            d3.select(`#link-to-${node.data.id}`).style('opacity', opacity);
        });
    }


    // Step 1: Calculate raw cumulative lengths
    const calculateRawCumulativeLength = (node, sum = 0) => {
        let rawLength = node.data.average_normalized_info_gain !== undefined ? node.data.average_normalized_info_gain : 0;
        node.data.rawCumulativeLength = sum + rawLength;

        if (node.children) {
            node.children.forEach(child => calculateRawCumulativeLength(child, node.data.rawCumulativeLength));
        }
    };

    calculateRawCumulativeLength(root);

    // Step 2: Find min and max cumulative lengths
    let allCumulativeLengths = root.descendants().map(node => node.data.rawCumulativeLength);
    let minCumulativeLength = Math.min(...allCumulativeLengths); // should be 0
    let maxCumulativeLength = Math.max(...allCumulativeLengths);

    // define an offset based on the minCumulativeLength, maxCumulativeLength and treeHeight
    // the factor 2 here is the space reserved for the root node and the first layer children nodes
    const lengthFactor = 2;
    let lengthOffset = lengthFactor * (maxCumulativeLength - minCumulativeLength) / treeHeight;


    // Step 3: Define a new xScale based on min and max cumulative lengths
    const xScale = d3.scaleLinear()
        .domain([minCumulativeLength, maxCumulativeLength + lengthOffset])
        .range([0, width]);

    // Step 4: Adjust each node's cumulative length using the xScale
    const adjustCumulativeLengths = (node) => {
        if (!node.parent) { // if root node, handle the positions for nodes at depth = 1, which are at position lengthOffset/lengthFactor
            // console.log("root node!")
            node.data.cumulativeLength = xScale(node.data.rawCumulativeLength + lengthOffset / lengthFactor);
        }
        else {
            node.data.cumulativeLength = xScale(node.data.rawCumulativeLength + lengthOffset);
        }


        if (node.children) {
            node.children.forEach(adjustCumulativeLengths);
        }
    };

    adjustCumulativeLengths(root);

    let pathIndex = 0; // Initialize a path index counter at the start of the drawTree function

    const assignPathIndex = (node, index = 0) => {
        // Assign the current path index to the node
        node.data.pathIndex = index;

        if (node.children && node.children.length > 0) {
            // If the node has children, increment the path index for each child and recursively call this function
            node.children.forEach((child, ci) => assignPathIndex(child, index * 10 + (ci + 1))); // Example indexing strategy
        }
    };

    assignPathIndex(root); // Call this function right after the root hierarchy is defined




    const tree = d3.tree().size([height, width]);
    tree(root);

    // console.log("root", root)

    // Define a color scale that maps the relevancy score to colors
    // The domain will be [0, 1] if your relevancy scores are normalized between 0 and 1.
    const colorScale = d3.scaleSequential(d3.interpolatePurples)
        .domain([2, -2]); // Set the domain according to the range of your relevancy scores

    const widthScale = d3.scaleLinear([0, 1], [0.5, 5])


    // Draw the links
    const link = svg.selectAll('.link')
        .data(root.links())
        .enter().append('path')
        .attr('class', 'link')
        .style('fill', 'none')
        .style('stroke', d => {
            // Check if the source node has the average_normalized_answer_relevancy property
            if (d.source.data.average_normalized_answer_relevancy !== undefined) {
                // Use the color scale for nodes with relevancy data
                return colorScale(d.source.data.average_normalized_answer_relevancy);
            } else {
                // Use black for root node links
                return "black";
            }
        })
        .style('stroke-dasharray', d => {
            // Apply dashed pattern for root node links
            return d.source.data.average_normalized_answer_relevancy === undefined ? "5,5" : "";
        })
        .style('stroke-width', d => {
            // console.log("d", d)
            return `${widthScale(d.source.data.average_normalized_gpt_response_length)}px`


        }) // Adjust based on your data scale
        .attr('d', d3.linkHorizontal()
            .x(d => {



                if (!d.parent) {

                    // Case 2: d.parent is undefined, means it is the root node.
                    return xScale(minCumulativeLength) // may need to adjust the translate value
                } else if (d.parent && d.parent.data.rawCumulativeLength !== 0) {
                    // Case 1: "d.parent.data.cumulativeLength" is defined, use the current format.
                    return d.parent.data.cumulativeLength;
                } else {
                    // Case 3: d.parent is defined, but d.parent.data.cumulativeLength is not defined, means it is the first leaf.
                    // Adjust the translate value based on the provided guidance.
                    return xScale(lengthOffset / 2);
                }


            })
            .y(d => {
                // console.log("d.x",d.x)
                return d.x
            }));




    // Define a pie layout
    const pie = d3.pie()
        .value(() => 1); // Equal value for each category, since we're only showing presence/absence

    // Define an arc generator
    const arc = d3.arc()
        .innerRadius(0);
    // Draw the nodes
    const node = svg.selectAll('.node')
        .data(root.descendants())
        .enter().append('g')
        .attr('id', d => `node-${d.data.id}`) // Add an ID to each node for easy selection
        .attr('class', d => `node${d.children ? ' node--internal' : ' node--leaf'}`)
        .attr('transform', d => {
            if (!d.parent) {

                // Case 2: d.parent is undefined, means it is the root node.
                return `translate(${xScale(minCumulativeLength)},${d.x})`; // may need to adjust the translate value
            } else if (d.parent && d.parent.data.rawCumulativeLength !== 0) {
                // Case 1: "d.parent.data.rawCumulativeLength" is 0, use the current format.
                return `translate(${d.parent.data.cumulativeLength},${d.x})`;
            } else {

                // Case 3: d.parent is defined, but d.parent.data.cumulativeLength is 0, means it is the first leaf.
                // Adjust the translate value based on the provided guidance.
                return `translate(${xScale(lengthOffset / 2)},${d.x})`;
            }
        })





    // Find the maximum count value
    // console.log("node",node.data())
    const maxCount = d3.max(node.data().filter(d => { return d.data.count }), d => d.data.count);



    const radiusScale = d3.scaleLog()
        .domain([1, maxCount]) // Assuming maxCount is your maximum value; log scale cannot start at 0
        .range([5, 25]); // Adjust the range based on your visualization needs

    // Nodes
    node.each(function (d) {
        // Set the radius for each node's pie chart
        // Make sure the radiusScale is provided with a valid number
        const nodeRadius = radiusScale(d.data.count || 1);
        // console.log("nodeRadius",nodeRadius)
        arc.outerRadius(nodeRadius);
        // console.log("arc",arc)

        // Select the current node
        const currentNode = d3.select(this);

        // Check if there are categories and if they are multiple
        if (d.data.categories && d.data.categories.length > 1) {
            // Create pie data, ensuring that each category has a valid color code
            const pieData = pie(d.data.categories.filter(cat => cat !== null && cat !== ""));

            // Create an arc for each piece of pie data
            currentNode.selectAll('path')
                .data(pieData)
                .enter()
                .append('path')
                .attr('d', arc)
                .attr('fill', (slice) => {
                    // Get the categoryCode corresponding to this slice of pie
                    const categoryCode = d.data.categories[slice.index];
                    // Ensure that the categoryCode is valid and return a color, or default if invalid
                    return categoryCode ? mapCategoryToColor(categoryCode) : 'lightsteelblue';
                });
        } else {
            // If there's only one category or none, just draw a circle
            // Check if categories exist and has an item before attempting to access it
            const categoryCode = d.data.categories && d.data.categories[0] ? d.data.categories[0] : 'lightsteelblue';
            currentNode.append('circle')
                .attr('r', radiusScale(d.data.count || 1))
                .style('fill', mapCategoryToColor(categoryCode)); // Use the color mapping function here
        }
    });


    // Assuming you have a function to generate abbreviations
    function abbreviateCode(code) {
        // Simple example: Take the first letter of each word to form an abbreviation
        return code.split(/\s+/).map(word => word[0]).join("");
    }

    // Then, use this function when setting the text
    node.append('text')
        .attr('dy', '.35em')
        .attr('x', d => d.children ? -5 : 5)
        .style('text-anchor', d => d.children ? 'end' : 'start')
        .text(d => d.data.codes ? d.data.codes.map(abbreviateCode).join(", ") : '') // Use abbreviateCode here
        .attr('font-size', '10px'); // Adjust font size to fit inside the node


    // Append student list tag component at the end of paths

    // Function to abbreviate names and task IDs
    function abbreviateNameAndTask(fullName, taskId) {
        // Abbreviate the name: take the first letter of each part of the name
        const nameAbbreviation = fullName
        .split('_') // Assuming names are separated by underscores
        .map(part => part.charAt(0)) // Take the first character of each part
        .join(''); // Join the initials
    
        // Abbreviate the task ID: assume format is "Task" followed by a number, convert to "T" followed by the number
        const taskAbbreviation = taskId.replace('Task', 'T');
    
        return `${nameAbbreviation}(${taskAbbreviation})`;
    }
   
    // add student tags and score visualization
// This part of the code adds the student name tags to the nodes
node.filter(d => d.data.average_score !== undefined && (!d.children || d.children.length === 0))
  .append('foreignObject')
  .attr('width', 110) // Set a width wide enough to fit the content
  .attr('height', 40) // Set a height that fits the content
  .attr('x', 30)
  .attr('y', -20)
  .attr('class', 'student-list-tag')
  .each(function(d) {
    let container = document.createElement('div');
    container.style.display = 'flex';
    container.style.alignItems = 'center';

    let studentTagsHtml = document.createElement('div');
    studentTagsHtml.className = 'student-tags';
    studentTagsHtml.style.textAlign = 'left';
    studentTagsHtml.style.overflow = 'hidden';
    studentTagsHtml.style.whiteSpace = 'nowrap';
    studentTagsHtml.style.textOverflow = 'ellipsis';

    // Generate student name tags
    d.data.student_list.forEach(s => {
      let span = document.createElement('span');
      span.className = "student-tag";
      span.style.backgroundColor = "#cccccc";
      span.style.color = "#ffffff";
      span.style.padding = "1px 4px";
      span.style.margin = "1px";
      span.style.display = "inline-block";
      span.style.borderRadius = "5px";
      span.style.cursor = "pointer";
      span.style.fontSize = "7.5px";
      span.style.fontWeight = "bold";
      span.style.boxShadow = "0 1px 2px rgba(0,0,0,.2)";
      span.innerText = abbreviateNameAndTask(s.student_name, s.task_id);
      span.onclick = () => handleStudentTagClick(s.student_name, s.task_id);
      studentTagsHtml.appendChild(span);
    });

    // Append the student name tags container
    container.appendChild(studentTagsHtml);

    // Append an empty div for the score bar container
    let scoreBarContainer = document.createElement('div');
    scoreBarContainer.className = 'score-bar-container';
    container.appendChild(scoreBarContainer);

    this.appendChild(container);
  });

// After all foreignObjects are appended to the DOM, calculate the width of each student name tag
// and add the score bar. This is done separately to ensure that the elements are rendered in the DOM
// and therefore have a measurable width.
d3.selectAll('.student-list-tag').each(function(d) {
    let foreignObject = d3.select(this);
    let studentTags = foreignObject.select('.student-tags').node();
    let scoreBarContainer = foreignObject.select('.score-bar-container');
  
    // 如果存在student-tags，则根据它们的宽度设置分数条的长度，否则使用默认最大长度
    let studentTagsWidth = Math.min(studentTags.getBoundingClientRect().width , 15) // 如果没有student-tags，使用默认值100px
  
    // 确定分数条的长度
    let scoreBarLength = studentTagsWidth
  
    // 限制分数条的长度，不超过student-tags的宽度
    scoreBarLength = Math.min(scoreBarLength, studentTagsWidth);

  let xOffset = 10; // Set the x offset as needed
  let yOffset = 20; // Set the y offset as needed, for example below the name tag

  // Create the score bar container and set the position with offsets
  let scoreBarContainerDiv = document.createElement('div');
  scoreBarContainerDiv.style.position = 'absolute'; // Position absolutely within the foreignObject
  scoreBarContainerDiv.style.left = `${xOffset}px`; // Apply the x offset
  scoreBarContainerDiv.style.top = `${yOffset}px`; // Apply the y offset
  
    // 创建并设置分数条样式
    let scoreBar = document.createElement('div');
    scoreBar.style.width = `${scoreBarLength}px`;
    scoreBar.style.height = '10px';
    scoreBar.style.backgroundColor = `rgba(0, 0, 0, ${0.1 + 0.9 * d.data.average_score})`;
    scoreBar.style.color = 'white';
    scoreBar.style.fontSize = '8px';
    scoreBar.style.textAlign = 'center';
    scoreBar.style.lineHeight = '10px';
    scoreBar.innerText = `${(d.data.average_score).toFixed(1)}`;
  
    // Append the score bar to its container
  scoreBarContainerDiv.appendChild(scoreBar);

  // Append the score bar container div to the score bar container
  scoreBarContainer.node().appendChild(scoreBarContainerDiv);
  });
// d3.selectAll('.student-list-tag').each(function(d) {
//   let foreignObject = d3.select(this);
//   let studentTagsWidth = foreignObject.select('.student-tags').node().getBoundingClientRect().width;
//   let scoreBarContainer = foreignObject.select('.score-bar-container');
  
//   // Now that we have the name tag width, append the score bars
//   // Create the score bar
// //   let scoreBarLength = Math.max(studentTagsWidth * 0.1, studentTagsWidth * d.data.average_score);
// let scoreBarLength = studentTagsWidth*0.5
//   let scoreBar = document.createElement('div');
//   scoreBar.style.width = `${scoreBarLength}px`;
//   scoreBar.style.height = '10px';
//   scoreBar.style.backgroundColor = `rgba(0, 0, 0, ${0.1 + 0.9 * d.data.average_score})`;
//   scoreBar.style.color = 'white';
//   scoreBar.style.fontSize = "10px";
//   scoreBar.style.textAlign = 'center';
//   scoreBar.style.lineHeight = '10px';
//   scoreBar.innerText = `${(d.data.average_score ).toFixed(1)}`;

//   // Append score bar to its container
//   scoreBarContainer.node().appendChild(scoreBar);
// });


  


       

    // Highlight paths that include the selected strategy index
    // Call the highlight paths function if a strategy is selected
    if (selectedStrategyIndex !== null) {
        highlightPaths(root, selectedStrategyIndex);
    }


    // Add hover interaction for nodes
    node.on("mouseover", function (event, d) {
        // Display codes as text if available
        if (d.data.codes) {
            d3.select(this)
                .append('text')
                .attr('class', 'hover-text-codes') // Add class for easy selection or styling
                .attr('dy', '-1.5em') // Adjust the position above the node
                .attr('x', 0) // Center the text above the node
                .style('text-anchor', 'middle') // Ensure the text is centered
                .text(d.data.codes.join(", ")) // Join the codes array into a string
                .attr('font-size', '10px') // Adjust font size as needed
                .attr('fill', nodeTextColor); // Color can be adjusted
        }

        if (d.data.name) {
            d3.select(this)
                .append('text')
                .attr('class', 'hover-text-name') // Add class for easy selection or styling
                .attr('dy', '3em') // Adjust the position above the node
                .attr('x', 0) // Center the text above the node
                .style('text-anchor', 'middle') // Ensure the text is centered
                .text(d.data.name) // Join the codes array into a string
                .attr('font-size', '10px') // Adjust font size as needed
                .attr('fill', nodeTextColor); // Color can be adjusted
        }

        if (d.data.count) {
            d3.select(this)
                .append('text')
                .attr('class', 'hover-text-count') // Add class for easy selection or styling
                .attr('dy', '4.5em') // Adjust the position above the node
                .attr('x', 0) // Center the text above the node
                .style('text-anchor', 'middle') // Ensure the text is centered
                .text(d.data.count) // Join the codes array into a string
                .attr('font-size', '10px') // Adjust font size as needed
                .attr('fill', nodeTextColor); // Color can be adjusted
        }

        // if (d.data.pathIndex) {
        //     // Display path index
        //     d3.select(this)
        //         .append('text')
        //         .attr('class', 'hover-text-path-index') // Add class for easy selection or styling
        //         .attr('dy', '-3em') // Position above the existing hover information
        //         .attr('x', 0)
        //         .style('text-anchor', 'middle')
        //         .text(`Path Index: ${d.data.pathIndex}`) // Display the path index
        //         .attr('font-size', '10px')
        //         .attr('fill', nodeTextColor);
        // }
    })
        .on("mouseout", function () {
            // Remove the text on mouseout
            d3.select(this).select('.hover-text-codes').remove();
            d3.select(this).select('.hover-text-name').remove();
            d3.select(this).select('.hover-text-count').remove();
            d3.select(this).select('.hover-text-path-index').remove(); // Remove the path index text on mouseout
        });

    //     // Define a scale for the arc's color based on the score
    // const scoreColorScale = d3.scaleSequential(d3.interpolateGreys)
    // .domain([0, 1]); // Assuming score is normalized between 0 and 1

    // // Define an arc generator for scores
    // const scoreArc = d3.arc()
    // .innerRadius(5) // Adjust according to your node size
    // .outerRadius(7.5) // This could be slightly larger than the inner radius
    // .startAngle(0) // Arc starts at 0
    // // End angle will be dynamically set based on score
    // node.filter(d => d.data.average_score !== undefined)
    // .filter(d => {
    //     // Your existing conditions to determine if the node displays a score
    //                 // Check if the node has no children
    //                 const isLeaf = !d.children || d.children.length === 0;
    //                 // Check if the node is a leaf in any path (sum of children's count is less than the node's count)
    //                 const isLeafInPath = d.children && d.data.count > d.children.reduce((sum, child) => sum + child.data.count, 0);
    //                 return isLeaf || isLeafInPath; // Node should satisfy at least one condition
    //     // return !d.children || d.children.length === 0;
    // })
    // .append('path')
    // .attr('y', -30)
    // .attr('x', 15)
    // .attr('d', d => {
    //     // Calculate the end angle based on the score
    //     // Assuming scores are normalized between 0 and 1, adjust if they're on a different scale
    //     const endAngle = (d.data.average_score * 2 * Math.PI); // Full circle for score=1
    //     return scoreArc.endAngle(endAngle)(d);
    // })
    // .attr('fill', d => scoreColorScale(d.data.average_score))
    // .attr('transform', (d) => {
    //     const radius = radiusScale(d.data.count || 1);
    //     // Convert the 'em' unit to pixels based on the current font size (10px in this case)
    //     // const emToPixels = 0.31 * 10; // Example conversion, adjust based on actual font size if different
    //     return `translate(${radius}, 0)`;
    // });

    // .attr('transform', d => `translate(${d.y},${d.x})`); // Adjust position based on your layout


    // only show the average score if it is at least one path's leaf node
    // node.filter(d => d.data.average_score !== undefined)
    //     .filter(d => {
    //         // Check if the node has no children
    //         const isLeaf = !d.children || d.children.length === 0;
    //         // Check if the node is a leaf in any path (sum of children's count is less than the node's count)
    //         const isLeafInPath = d.children && d.data.count > d.children.reduce((sum, child) => sum + child.data.count, 0);
    //         return isLeaf || isLeafInPath; // Node should satisfy at least one condition
    //     })
    //     .append('text')
    //     .attr('dy', '0.31em')
    //     .attr('x', '5em')
    //     .style('text-anchor', 'middle')
    //     .text(d => ` ${d.data.average_score.toFixed(1)}`)
    //     .attr('font-size', '10px')
    //     .attr('fill', 'darkgreen') // Color can be adjusted
    //     .attr('transform', (d) => {
    //         const radius = radiusScale(d.data.count || 1);
    //         // Convert the 'em' unit to pixels based on the current font size (10px in this case)
    //         // const emToPixels = 0.31 * 10; // Example conversion, adjust based on actual font size if different
    //         return `translate(${radius}, 0)`;
    //     });

    // Optional: Handle mouse events for detailed interaction, such as tooltips or highlighting
    function highlightPath(d) {
        // Reset all nodes and links to full opacity
        svg.selectAll('.link, .node').style('opacity', 0.1);

        // Highlight the path from the selected node to the root
        let currentNode = d;
        // console.log("currentNode", currentNode)
        while (currentNode) {
            // Highlight the current node
            svg.selectAll('.node')
                .filter(node => node === currentNode)
                .style('opacity', 1);

            // Highlight the link to the parent node
            svg.selectAll('.link')
                .filter(link => link.target === currentNode)
                .style('opacity', 1);

            currentNode = currentNode.parent;
        }

        // Highlight all children of the selected node
        function highlightChildren(node) {
            if (!node.children) return;
            node.children.forEach(child => {
                svg.selectAll('.node').filter(n => n === child).style('opacity', 1);
                svg.selectAll('.link').filter(l => l.source === node && l.target === child).style('opacity', 1);
                highlightChildren(child); // Recursively highlight children
            });
        }
        highlightChildren(d);
    }

 

    // Modify the node click function to handle highlighting and drawing dashed lines correctly
    svg.selectAll('.node').on('click', function (event, d) {
        event.stopPropagation(); // Prevent the SVG canvas click handler from firing

        // Dim all nodes and links by setting their opacity to 0.1
        svg.selectAll('.link, .node').style('opacity', 0.1);
        svg.selectAll('.tempLink').remove(); // Remove any existing temporary links

        // First, highlight the path from the selected node to the root
        let currentNode = d;
        console.log("currentNode", currentNode)
        while (currentNode) {
            svg.selectAll('.node')
                .filter(node => node === currentNode)
                .style('opacity', 1)
                .selectAll('text') // Select all text elements within the matching node
                .style('fill', 'red'); // Change their text color to red

            svg.selectAll('.link')
                .filter(link => link.target === currentNode)
                .style('opacity', 1);

            currentNode = currentNode.parent;
        }

     
        // Highlight all children of the selected node recursively
        function highlightChildren(node) {
            if (!node.children) return;
            node.children.forEach(child => {
                svg.selectAll('.node').filter(n => n === child).style('opacity', 1);
                svg.selectAll('.link').filter(l => l.source === node && l.target === child).style('opacity', 1);
                highlightChildren(child);
            });
        }
        highlightChildren(d);

        const selectedName = d.data.name;
        const selectedNode = d;

        // Find and highlight all nodes with the same name
        const matchingNodes = root.descendants().filter(node => node.data.name === selectedName && node !== selectedNode);
        matchingNodes.forEach(matchingNode => {
            svg.selectAll('.node')
                .filter(node => node === matchingNode)
                .style('opacity', 1)// Highlight matching nodes
                .selectAll('text') // Select all text elements within the matching node
                .style('fill', 'red'); // Change their text color to red 

            // Draw dashed lines to all matching nodes
            svg.append('path')
                .attr('class', 'tempLink') // Temporary link class for easy removal
                .style('fill', 'none')
                .style('stroke', 'black')
                .style('stroke-dasharray', ('3, 3'))
                .style('opacity', 0.35)
                .attr('d', () => {
                    const start = svg.selectAll('.node').filter(node => node === selectedNode).node().getBoundingClientRect();
                    const end = svg.selectAll('.node').filter(node => node === matchingNode).node().getBoundingClientRect();
                    const svgRect = svgContainer.node().getBoundingClientRect();

                    // Adjust coordinates based on SVG position

                    const startX = selectedNode.parent.data.cumulativeLength; // Assuming horizontal layout: this is correct
                    const startY = selectedNode.x;
                    const endX = matchingNode.parent.data.cumulativeLength; //.parent.data.cumulativeLength
                    const endY = matchingNode.x;

                    // Return the path data for a line

                    return `M${startX},${startY}L${endX},${endY}`;
                });
        });




    });

}

