import React, { useEffect } from 'react';
import * as d3 from 'd3';
import Header from './Header';
const { mapLabelToCategory } = require('../helper/labelCategoryMapping');

function ComparisonView({ selectedNodes = [] }) {
    // Log the selectedNodes to verify the data structure
    console.log('Received selectedNodes:', selectedNodes);


    // Define the dimensions and margins for the SVGs
    const bidirectionalBarChartDimensions = {
        width: 300, // Example width, adjust as necessary
        height: 200, // Example height, adjust as necessary
    };
    const svgDimensions = {
        width: 450, // Example width, adjust as necessary
        height: 200, // Example height, adjust as necessary
    };

    const margin = { top: 5, right: 20, bottom: 5, left: 20 }

    // A function to construct the full path from a node to the root
    const constructPath = (node) => {
        const path = [];
        let currentNode = node;
        while (currentNode) {
            path.push(currentNode);
            currentNode = currentNode.parent;
        }
        // Return the path in the correct order, from root to node
        return path.reverse();
    };

    // A hook to perform D3 visualizations after the component mounts
    useEffect(() => {
        if (selectedNodes.length > 1) {
            // Assuming we only compare two paths
            const path1 = constructPath(selectedNodes[0]);
            const path2 = constructPath(selectedNodes[1]);
            const path1Index = selectedNodes[0].data.pathIndex;
            const path2Index = selectedNodes[1].data.pathIndex;
            const colors = {
                path1Color: '#779ECB',
                path1BorderColor: "#779ECB",
                path2BorderColor: "#FFD580",
                path2Color: '#FFD580'
            }
    
            console.log('Path 1:', path1);
            console.log('Path 2:', path2);

            // Calculate mean metrics, convert categories, etc.
            // Implement these functions based on the logic we discussed
            const [meanMetricsPath1,count1] = calculateMeanMetrics(path1);
            const [meanMetricsPath2,count2] = calculateMeanMetrics(path2);
            const categoryCountsPath1 = convertAndMapCategories(path1);
            const categoryCountsPath2 = convertAndMapCategories(path2);
            const labelList1 = convertLabelList(path1);
            const labelList2 = convertLabelList(path2);
            console.log('Mean metrics for path 1:', meanMetricsPath1);
            console.log('Mean metrics for path 2:', meanMetricsPath2);
            console.log('Category counts for path 1:', categoryCountsPath1);
            console.log('Category counts for path 2:', categoryCountsPath2);
            console.log('Label List for path 1:', labelList1);
            console.log('Label List for path 2:', labelList2);
            const pathIndexs = [path1Index, path2Index];
            const counts = [count1, count2];

            // Now we have the data, call functions to draw D3 visualizations
            // These functions need to be implemented with D3.js logic
            drawBidirectionalBarChart(meanMetricsPath1, meanMetricsPath2, pathIndexs,counts, colors);
            drawHexagonChart(categoryCountsPath1, categoryCountsPath2, colors);
            drawMatrixVisualization(labelList1, labelList2,pathIndexs);
        }
    }, [selectedNodes]); // Dependency array, re-run the effect when selectedNodes changes

    // Placeholder functions for D3.js logic
    // These should be replaced with actual implementations


    const calculateMeanMetrics = (path) => {
        if (!Array.isArray(path) || path.length === 0) {
            return {
                metrics: {
                    answer_relevancy: 0,
                    gpt_response_length: 0,
                    info_gain: 0,
                    average_score: 0,
                },
                count: 0, // Return count separately
            };
        }
    
        let metrics = {
            answer_relevancy: 0,
            gpt_response_length: 0,
            info_gain: 0,
            average_score: 0,
        };
        let count = 0; // Keep track of count separately
    
        path.forEach(node => {
            if (node.data && 'average_normalized_answer_relevancy' in node.data) {
                metrics.answer_relevancy += node.data.average_normalized_answer_relevancy || 0;
                metrics.gpt_response_length += node.data.average_normalized_gpt_response_length || 0;
                metrics.info_gain += node.data.average_normalized_info_gain || 0;
                metrics.average_score += node.data.average_score || 0;
                count++; // Increment count for each valid node
            }
        });
    
        if (count > 0) {
            Object.keys(metrics).forEach(key => {
                metrics[key] /= count;
            });
        }
    
        return [metrics, count]  ; // Return metrics and count separately
    };
    


    const convertAndMapCategories = (path) => {
        const categoryCounts = {};

        // Check if path is an array
        if (!Array.isArray(path)) {
            console.error('Path is not an array:', path);
            return categoryCounts;
        }

        // This part need to be revised. The data structure is not clear
        //
        //
        //
        //
        path.forEach(node => {
            // Check if node.data exists and has a categories array
            // not category, should be "codes" in the data
            if (node.data && Array.isArray(node.data.codes)) {
                node.data.codes.forEach(category => {
                    // Ensure the category is a string or can be converted to a string
                    if (category !== undefined && category !== null) {
                        // const mappedCategory = mapLabelToCategory(String(category));
                        const mappedCategory = mapLabelToCategory(String(category));
                        // console.log('Mapped category:', mappedCategory);
                        if (typeof mappedCategory === 'string' || typeof mappedCategory === 'number') {
                            categoryCounts[mappedCategory] = (categoryCounts[mappedCategory] || 0) + 1;
                        } else {
                            console.error('mapLabelToCategory did not return a valid category for:', category);
                        }
                    }
                });
            }
        });

        return categoryCounts;
    };


    const convertLabelList = (path) => {
        const categoryCounts = {};

        // Check if path is an array
        if (!Array.isArray(path)) {
            console.error('Path is not an array:', path);
            return categoryCounts;
        }

        // This part need to be revised. The data structure is not clear
        //
        //
        //
        //
        path.forEach(node => {
            // Check if node.data exists and has a categories array
            // not category, should be "codes" in the data
            if (node.data && Array.isArray(node.data.codes)) {
                node.data.codes.forEach(category => {
                    // Ensure the category is a string or can be converted to a string
                    if (category !== undefined && category !== null) {
                        // const mappedCategory = mapLabelToCategory(String(category));
                        const mappedCategory = category
                        // console.log('Mapped category:', mappedCategory);
                        if (typeof mappedCategory === 'string' || typeof mappedCategory === 'number') {
                            categoryCounts[mappedCategory] = (categoryCounts[mappedCategory] || 0) + 1;
                        } else {
                            console.error('mapLabelToCategory did not return a valid category for:', category);
                        }
                    }
                });
            }
        });

        return categoryCounts;
    };


    const drawBidirectionalBarChart = (metrics1, metrics2, pathIndexs, counts, colors) => {
        // Select the SVG element and set dimensions
        const path1Color = colors.path1Color
        const path2Color = colors.path2Color
        const svg = d3.select('#bidirectional-bar-chart');
        const margin = { top: 45, right: 15, bottom: 15, left: 75 };
        const width = +svg.attr('width') - margin.left - margin.right;
        const height = +svg.attr('height') - margin.top - margin.bottom;

        // Clear any previous content
        svg.selectAll('*').remove();

        // Calculate the differences between the two paths for each metric
        const metricDifferences = Object.keys(metrics1).map(metric => {
            return {
                metric: metric,
                difference: metrics1[metric] - metrics2[metric]
            };
        });
        console.log("metricDifferences", metricDifferences)

        // Create a scale for the metrics
        const xScale = d3.scaleLinear()
            .domain(d3.extent(metricDifferences, d => d.difference))
            .range([0, width])
            // .padding(0.1);

        // Assuming metricDifferences is an array of objects
        // and each object has a property named 'difference'
        const minDifference = d3.min(metricDifferences, d => d.difference);

        // console.log(minDifference); // This will log the minimum 'difference' value to the console


        // Create a scale for the categories
        const yScale = d3.scaleBand()
            .domain(metricDifferences.map(d => d.metric))
            .rangeRound([0, height])
            .padding(0.1);

        // Main group element
        const g = svg.append('g').attr('transform', `translate(${margin.left},${margin.top})`);

        // Add bars for the differences
        g.selectAll('.metric-bar')
            .data(metricDifferences)
            .enter()
            .append('rect')
            .attr('class', 'metric-bar')
            .attr('x', d => xScale(Math.min(Math.max(0,minDifference), d.difference)))
            .attr('y', d => yScale(d.metric))
            .attr('width', d => Math.abs(xScale(d.difference) - xScale(0)))
            .attr('height', yScale.bandwidth())
            .attr('fill', d => d.difference >= 0 ? path1Color : path2Color);

        // Add the X axis at the bottom
        g.append('g')
            .attr('transform', `translate(0,${height})`)
            .call(d3.axisBottom(xScale))
            .attr('font-size', '7.5px');

        // Add the Y axis to the left
        g.append('g')
            .call(d3.axisLeft(yScale).tickSize(0))
            .attr('font-size', '7.5px');

        // Add legend
        const legend = svg.append('g')
            .attr('font-size', '10px')
            .attr('text-anchor', 'end')
            .selectAll('g')
            .data(pathIndexs) // assuming pathIndexs is an array like ['Path 1', 'Path 2']
            .enter().append('g')
            .attr('transform', (d, i) => `translate(0,${i * 20})`);

        legend.append('rect')
            .attr('x', width + margin.right - 19)
            .attr('width', 19)
            .attr('height', 19)
            .attr('fill', (d, i) => i === 0 ? path1Color : path2Color);

        legend.append('text')
            .attr('x', width + margin.right - 24)
            .attr('y', 9.5)
            .attr('dy', '0.32em')
            .text((d, i) => `${d} (Depth: ${counts[i]})`);
    };



    // Helper function to calculate coordinates for a line given an angle and length
    const radialLineCoords = (angle, length) => {
        // Round the values to avoid floating-point arithmetic issues
        const x = Math.cos(angle - Math.PI / 2) * length;
        const y = Math.sin(angle - Math.PI / 2) * length;
        return {
        x: Math.round(x * 100) / 100, // Rounding to 2 decimal places
        y: Math.round(y * 100) / 100
        };
    };


    const drawHexagonChart = (categoryCounts1, categoryCounts2,colors) => {
        const path1Color = colors.path1Color
        const path1BorderColor = colors.path1BorderColor
        const path2BorderColor = colors.path2BorderColor
        const path2Color = colors.path2Color
        // Ensure we have 8 categories, filled with 0 if not present
        const baseCategories = ['Remember', 'Understand', 'Apply', 'Analyze', 'Evaluate', 'Create', 'General prompt strategies from literature', 'Others'];

        // Mapping for category names
    const categoryNameMapping = {
        'General prompt strategies from literature': 'Strategy',
        // Add any other mappings here if necessary
    };

    // Function to get the short name for a category
    const getShortCategoryName = (category) => categoryNameMapping[category] || category;

        const filteredCounts1 = {};
        const filteredCounts2 = {};

        baseCategories.forEach(category => {
            filteredCounts1[category] = categoryCounts1[category] + 1 ||  1;
            filteredCounts2[category] = categoryCounts2[category]+ 1 ||  1;
        });


        // Select the SVG canvas and set dimensions
        const svg = d3.select('#hexagon-chart');
        const margin = { top: 25, right: 25, bottom: 25, left: 25 };
        const width = +svg.attr('width') - margin.left - margin.right;
        const height = +svg.attr('height') - margin.top - margin.bottom;

        // Clear SVG in case of redraw
        svg.selectAll("*").remove();

        // Append a group element to the SVG, which will hold our chart
        const g = svg.append('g')
            .attr('transform', `translate(${margin.left + width / 2}, ${margin.top + height / 2})`);

        // Radius of the hexagon chart, setting a minimum value to avoid negative scales
        const radius = Math.min(width, height) / 2;
        // console.log("radius", radius)

        // Create radial scale, ensuring that the domain minimum is 0 to avoid NaN issues
        const radialScale = d3.scaleLinear()
            .domain([0, Math.max(...Object.values(filteredCounts1), ...Object.values(filteredCounts2))])
            .range([0, radius]);

        // Create the axes and labels
        const angleSlice = Math.PI * 2 / baseCategories.length;
        baseCategories.forEach((category, i) => {
            const angle = angleSlice * i;
            const lineCoords = radialLineCoords(angle, radius);
            // console.log("lineCoords",lineCoords)



            // Draw the outermost hexagon shape by connecting the endpoints of each axis
            if (i === 0) {
                // Then in your drawHexagonChart function
                const hexagonPoints = baseCategories.map((_, j) => radialLineCoords(angleSlice * j, radius));
                // console.log("hexagonPoints", hexagonPoints);
                // Assuming you have your `hexagonPoints` array of objects with x and y properties

                // Convert the array of point objects to an array of point arrays
                const pointsForPath = hexagonPoints.map(point => [point.x, point.y]);

                // Create the hexagon path string using d3.line()
                const hexagonPath = d3.line()(pointsForPath.concat([pointsForPath[0]])); // Close the path by adding the first point to the end

                // Now you can use this path string to set the 'd' attribute of your SVG path element
                g.append('path')
                .attr('d', hexagonPath)
                .attr('stroke', 'black')
                .attr('stroke-width', 1)
                .attr('fill', 'none');

            }

            // Draw axis line
            g.append('line')
                .attr('x1', 0)
                .attr('y1', 0)
                .attr('x2', lineCoords.x)
                .attr('y2', lineCoords.y)
                .attr('stroke', 'black')
                .attr('stroke-width', 1);

            // Add category label
            const labelCoords = radialLineCoords(angle, radius + 20); // Offset for label
            g.append('text')
                .attr('x', labelCoords.x)
                .attr('y', labelCoords.y)
                .attr('text-anchor', 'middle')
                .attr('alignment-baseline', 'middle')
                .attr('font-size', '10px')
                .text(getShortCategoryName(category)); // Use the short name
        });

        // Draw the radar areas for each dataset
        drawRadarArea(g, baseCategories, filteredCounts1, radialScale, angleSlice, path1Color, path1BorderColor);
        drawRadarArea(g, baseCategories, filteredCounts2, radialScale, angleSlice, path2Color, path2BorderColor);


    };


    // Function to draw the radar area for a given dataset
    // Function to draw the radar area for a given dataset
    const drawRadarArea = (g, categories, categoryCounts, radialScale, angleSlice, fillColor, strokeColor) => {
        // Calculate the points for the radar area
        const radarLine = d3.line().curve(d3.curveLinearClosed);
        const dataPoints = categories.map((category, i) => {
            const value = categoryCounts[category];
            const angle = angleSlice * i;
            const coords = radialLineCoords(angle, radialScale(Math.max(value, 0))); // Use max to avoid negative scale
            return [coords.x, coords.y]; // Convert to an array of [x, y]
        });

        // Draw the path for the radar area
        g.append('path')
            .datum(dataPoints) // Bind the array of points for the path
            .attr('d', radarLine) // Create the path data
            .attr('fill', fillColor)
            .attr('fill-opacity', 0.5)
            .attr('stroke', strokeColor)
            .attr('stroke-width', 2);
    };


    const drawMatrixVisualization = (categoryCounts1, categoryCounts2,pathIndexs) => {
        // Select the SVG element and set dimensions
        const path1Index = pathIndexs[0];
        const path2Index = pathIndexs[1];
        const svg = d3.select('#matrix-visualization');
        const size = 30; // size of the matrix cell
        const categories = Object.keys({ ...categoryCounts1, ...categoryCounts2 });
        const maxLength = Math.max(...categories.map(cat => cat.length)); // Find the max length for label spacing
        const labelOffset = maxLength * 3; // Estimate space for labels based on character count

        const maxValue = d3.max([...Object.values(categoryCounts1), ...Object.values(categoryCounts2)]);
        console.log("maxValue", maxValue)
        // Define a color scale for counts
        const colorScale = d3.scaleSequential(d3.interpolateBlues)
            .domain([0, maxValue]);

        // Clear any previous content
        svg.selectAll('*').remove();

        // Set the SVG dimensions based on the number of categories
        svg.attr('width', categories.length * size + labelOffset)
            .attr('height', size * 6);

        // Draw a cell for each category count
        categories.forEach((category, i) => {
            // Path 1
            svg.append('rect')
                .attr('x', i * size + labelOffset/3)
                // .attr('x', i * size )
                .attr('y', 0)
                .attr('width', size)
                .attr('height', size)
                .attr('fill', colorScale(categoryCounts1[category] || 0));

            // Path 2
            svg.append('rect')
                .attr('x', i * size + labelOffset/3)
                // .attr('x', i * size )
                .attr('y', size)
                .attr('width', size)
                .attr('height', size)
                .attr('fill', colorScale(categoryCounts2[category] || 0));
        });

        // Add labels
        categories.forEach((category, i) => {
            const x = i * size + labelOffset/1.5 -10 ; // 15 here is a magic number that centers the text. need to adjust later
            const y = size * 2.5 ;
            svg.append('text')
                .attr('x', x)
                .attr('y', y)
                .text(category)
                .attr('text-anchor', 'end')
                // Rotate around the bottom-left corner of the text
                .attr('transform', `rotate(-60, ${x}, ${y})`)
                .attr('font-size', '7.5px')
                .attr('alignment-baseline', 'middle');


        });

 // Add pathIndex for Path 1 as a right y-axis label
 svg.append('text')
 .attr('x', categories.length * size + labelOffset/3) // Position text at the right side of the matrix
 .attr('y', size / 2) // Align vertically with the middle of the first row
 .text(`Path: ${pathIndexs[0]}`) // Display first path index
 .attr('font-size', '10px')
 .attr('text-anchor', 'start'); // Anchor text at the start

// Add pathIndex for Path 2 as a right y-axis label
svg.append('text')
 .attr('x', categories.length * size + labelOffset/3) // Position text at the right side of the matrix
 .attr('y', size * 1.5) // Align vertically with the middle of the second row
 .text(`Path: ${pathIndexs[1]}`) // Display second path index
 .attr('font-size', '10px')
 .attr('text-anchor', 'start'); // Anchor text at the start

    };




    return (
        <div className="comparison-view">
            <h2>Comparison View</h2>
            {selectedNodes.length > 0 ? (
                <div>
                    {/* Add SVG elements for D3 visualizations */}
                    <svg id="bidirectional-bar-chart" width={bidirectionalBarChartDimensions.width} height={bidirectionalBarChartDimensions.height}></svg>
                    <svg id="hexagon-chart" width={svgDimensions.width} height={svgDimensions.height}></svg>
                    <svg id="matrix-visualization" width={svgDimensions.width} height={svgDimensions.height}></svg>
                </div>
            ) : (
                <p>No paths selected.</p>
            )}
        </div>
    );
}

export default ComparisonView;
