import React, { useEffect, useRef, useState } from 'react';
import * as d3 from 'd3';
import { mapCategoryToColor } from '../helper/categoryColorMapping';

const StackedBarChart = ({ groupData, width, height, isHighlighted, onBarClick }) => {
  const svgRef = useRef();
  const [containerWidth, setContainerWidth] = useState(0);
  const wrapperRef = useRef();

  const fixedUniqueCategories = [
    "Remember", "Understand", "Apply", "Analyze", "Evaluate",
    "Create", "General prompt strategies from literature", "Others"
  ];

  useEffect(() => {
    setContainerWidth(wrapperRef.current ? wrapperRef.current.offsetWidth : 0);
    // Add a resize observer to handle window resize events
    const resizeObserver = new ResizeObserver(entries => {
      if (!entries || entries.length === 0) {
        return;
      }
      setContainerWidth(entries[0].contentRect.width);
    });

    if (wrapperRef.current) {
      resizeObserver.observe(wrapperRef.current);
    }

    return () => {
      if (wrapperRef.current) {
        resizeObserver.unobserve(wrapperRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (groupData && containerWidth > 0) {
      drawStackedBarChart(containerWidth, height);
    }
  }, [groupData, containerWidth, height, isHighlighted]);

  const drawStackedBarChart = (width, height) => {
    d3.select(svgRef.current).selectAll("*").remove();

    const height_constraint = height * 0.3
    const width_constraint = width * 0.3
    const margin = { top: 5, right: 15, bottom: 10, left: 15 };
    // need to define the width of the stacked bar chart
    const chartWidthPercentage = [0.7,0.1,0.1,0.1]; // 50% of the width
    // const donutChartHeight = height * 0.3; // 30% for the donut chart, 70% for the stacked bar chart
    const donutChartHeight = d3.min([height_constraint, width_constraint]);
    const chartWidth = (width - margin.left - margin.right)*chartWidthPercentage[0] ; // here only have 50% for the stacked bar chart
    const chartHeight = height - margin.top - margin.bottom-donutChartHeight;

    // Sort group and individual distributions according to fixedUniqueCategories
    groupData.label_distribution.sort((a, b) =>
      fixedUniqueCategories.indexOf(a.label_category) - fixedUniqueCategories.indexOf(b.label_category)
    );

    if (groupData.student_list) {
      groupData.student_list.forEach(student => {
        student.student_label_distribution.sort((a, b) =>
          fixedUniqueCategories.indexOf(a.label_category) - fixedUniqueCategories.indexOf(b.label_category)
        );
      });
    } else if (groupData.task_list) {
      groupData.task_list.forEach(task => {
        task.task_label_distribution.sort((a, b) =>
          fixedUniqueCategories.indexOf(a.label_category) - fixedUniqueCategories.indexOf(b.label_category)
        );
      });
    }


    const groupName = typeof groupData.category === 'number' ? `group_${groupData.category}` : groupData.category;
    const individualList = groupData.student_list || groupData.task_list;
    const listKey = groupData.student_list ? 'student_list' : 'task_list';
    const idKey = groupData.student_list ? 'student_id' : 'task_id';
    const labelDistributionKey = groupData.student_list ? 'student_label_distribution' : 'task_label_distribution';
    const individualCount = individualList.length;

    const tooltipKeys = groupData.student_list ? ['average_cs_background', 'average_gpt_background','average_vis_background','avg_score'] : ['average_task_difficulty', 'avg_score'];





    // Calculate average counts for group
    const groupAverages = groupData.label_distribution.map(ld => ({
      label: ld.label_category,
      averageCount: ld.label_count / individualCount
    }));

    // Map to the format required for d3.stack
    const groupLabels = groupAverages.map(ld => ld.label); // each sub bar category for group level data
    // const groupValues = groupLabels.map(label => { // each sub bar value for group level data
    //   const item = groupAverages.find(ld => ld.label === label);
    //   return item ? item.averageCount : 0;
    // });

    const individualsData = individualList.map(individual => { // for each individual student or task
      return groupLabels.map(label => { // for each label category this group has
        const labelItem = individual[labelDistributionKey].find(ld => ld.label_category === label);
        return labelItem ? labelItem.label_count : 0;
      });
    });

    // const stackedData = [groupValues, ...individualsData];
    const stackedData = individualsData; // Now it just has the individual data

    // console.log('stackedData:', stackedData);
    // console.log('groupLabels:', groupLabels);

    // Create stack
    const stack = d3.stack().keys(d3.range(groupLabels.length)); // create a key for each label category
    const series = stack(stackedData).map((layer, layerIndex) => {
      // Add additional properties to the layer
      layer.forEach((point, pointIndex) => {
        // if (pointIndex === 0) {
        //   // First point represents the group
        //   point.data.individual = groupName;
        //   point.data.group = groupName;
        //   point.data.fullData = groupData; // Entire group data
        // } else {
          // Subsequent points represent individuals
          // const individualIndex = pointIndex - 1; // Adjust the index for groupData[listKey]
          const individualIndex = pointIndex ; // Adjust the index for groupData[listKey]
          const individualData = groupData[listKey][individualIndex];
          point.data.individual = individualData.student_id || individualData.task_id;
          point.data.group = groupName;
          point.data.fullData = individualData; // Individual's data
        // }
        // Logging the data for verification
        //   console.log('groupData:', groupData);
        //   console.log('groupData[listKey]:', groupData[listKey]);
        //   console.log('pointIndex:', pointIndex);
      });
      return layer;
    });





    // Scales for the stacked bar chart
    const xScale = d3.scaleLinear()
      .domain([0, d3.max(series, layer => d3.max(layer, d => d[1]))])
      .range([margin.left, chartWidth - margin.right]);

    // const yDomain = [groupName, ...individualList.map(d => d.student_id || d.task_id)];
    // const yScale = d3.scaleBand()
    //   .domain(yDomain)
    //   .range([margin.top, chartHeight - margin.bottom])
    //   .padding(0.1);
    // Update the domain of yScale to remove the group name
    const yDomain = individualList.map(d => d.student_id || d.task_id); // removed groupName from array
    const yScale = d3.scaleBand()
      .domain(yDomain)
      .range([margin.top, chartHeight - margin.bottom])
      .padding(0.1);

 // Define a function to calculate the maximum end of the bars
 const calculateMaxEnd = (data, scale) => d3.max(data, d => scale(d));

 let extraBarsStartX = calculateMaxEnd(series.flatMap(d => d.map(dd => dd[1])), xScale) + 10; // Add some space between the stacked bars and the extra metric bars


    //////////////////////
    const metricsNames = ['IG', 'RL', 'Score'];
    const metricColors = ['#cccccc', '#cccccc', '#888888']; // Set different shades of grey for each metric if required
    //////////////////////


    // Create SVG
    const svg = d3.select(svgRef.current)
      .attr("width", width)
      .attr("height", height)
      .append("g")
      .attr("transform", `translate(${margin.left},${margin.top})`);


    //////////////////////////////////////////////////
    // Create a pie chart for the group-level summary
    // Calculate summary data for the donut chart
    // Calculate the outer radius of the donut chart based on your chart dimensions
    const donutOuterRadius = donutChartHeight/3; // Adjust this as needed
    const donutInnerRadius = donutOuterRadius / 1.5; // Adjust this for the donut thickness you want

    // Positioning the donut chart
    // const donutChartX = margin.left + donutOuterRadius; // X position
    const donutChartX =  donutOuterRadius; // X position
    const donutChartY = margin.top + donutOuterRadius; // Y position

    // Pie generator for the donut chart
    const pie = d3.pie()
      .sort(null) // Do not sort, keep the original order
      .value(d => d.label_count)
      ; // Assuming your data has a 'label_count' property

    // Arc generator for the donut chart
    const arc = d3.arc()
      .innerRadius(donutInnerRadius)
      .outerRadius(donutOuterRadius);

    // Draw the donut chart and the tooltip
    // Ensure there's a tooltip div in your HTML, or create one
const tooltip = d3.select('body').append('div')
.attr('class', 'tooltip')
.style('position', 'absolute')
.style('background-color', 'rgba(255, 255, 255, 0.8)')
.style('border', '1px solid #ddd')
.style('border-radius', '5px')
.style('padding', '10px')
.style('display', 'none')
.style('pointer-events', 'none'); // Ensure the tooltip doesn't interfere with mouse events

    
    const groupSummaryData = groupData.label_distribution; // Assuming this structure
    svg.append("g")
      .attr("transform", `translate(${donutChartX}, ${donutChartY})`)
      .selectAll("path")
      .data(pie(groupSummaryData))
      .enter()
      .append("path")
      .attr("d", arc)
      .attr("fill", d => mapCategoryToColor(d.data.label_category))
      .on('click', function (event, d) {
        // Now d has the properties 'group' and 'individual'
        // console.log(d); // The group this bar belongs to
        // console.log(d.data.group); // The group this bar belongs to
        // console.log(d.data.individual); // The individual label/category this bar represents
        // // Full group data can also be accessed if needed
        onBarClick(groupName, groupName); // Call the passed in onBarClick function with the group and individual identifiers
      })
      .on('mouseover', function (event, d) {
        tooltip
          .style('display', 'inline-block')
          .style('left', `${event.pageX + 10}px`)
          .style('top', `${event.pageY + 10}px`)
          .html(() => {
            // Construct the tooltip content
            const data = groupData; // Data bound to this slice
            // console.log('data:', data);
            const tooltipData = tooltipKeys.map(key => `<strong>${key.replace('_', ' ')}:</strong> ${data[key].toFixed(2)}`);
            return `<div>${groupName}</div>${tooltipData.join('<br>')}`;
          });
      })
      .on('mousemove', function (event) {
        tooltip
          .style('left', `${event.pageX + 10}px`)
          .style('top', `${event.pageY + 10}px`);
      })
      .on('mouseout', function () {
        tooltip.style('display', 'none');
      });

    let fontSize = 7.5;
    let textOffset = fontSize*2;
    // Add a label for the group name
    svg.append('text')
      .attr('transform', `translate(${donutChartX - textOffset}, ${donutChartY})`)
      .text(groupName)
      .attr('font-size', fontSize);

      
    //////////////////////////////////////////////////
    /// draw the metrics arcs 
    //////////////////////////////////////////////////

    // Additional metric scales - normalized between [0, 1]
    // New scales for GPT and Score metrics



    const metricValueScale = d3.scaleLinear()
      .domain([0, 1])
      .range([0, 2 * Math.PI]); // full circle

    // Draw grey arcs for metrics
    const arcMetrics = d3.arc()
      .innerRadius(donutInnerRadius * 1.1) // slightly outside the donut inner radius
      .outerRadius(donutOuterRadius * 1.1); // slightly outside the donut outer radius

    // Calculate metric arc lengths and draw them
    // Assuming you have a way to calculate the normalized metric values
    // Normalization scales for each metric
    const normalizeInfoGain = d3.scaleLinear()
      .domain([d3.min(individualList, d => d.avg_gpt_response_info_gain), d3.max(individualList, d => d.avg_gpt_response_info_gain)])
      .range([0, 1]);

    const normalizeResponseLength = d3.scaleLinear()
      .domain([d3.min(individualList, d => d.avg_normed_gpt_response_length), d3.max(individualList, d => d.avg_normed_gpt_response_length)])
      .range([0, 1]);

    const normalizeScore = d3.scaleLinear()
      .domain([d3.min(individualList, d => d.avg_score), d3.max(individualList, d => d.avg_score)])
      .range([0, 1]);



    // Function to draw metric arcs beside the donut chart
    const drawMetricArcs = (centerX, centerY, innerRadius, outerRadius) => {
      const arcWidth = innerRadius * 0.1; // Width of the metric arcs, adjust as needed
      const metricsArc = d3.arc()
        .innerRadius(innerRadius)
        .outerRadius(innerRadius + arcWidth);

        const metricsArcScore = d3.arc()
        .innerRadius(innerRadius - arcWidth*1.5)
        .outerRadius(innerRadius + arcWidth*1.5);
      const arcList = [metricsArc, metricsArc, metricsArcScore]

      // Assuming groupData has normalized values for these metrics
      const groupMetrics = {
        infoGain: normalizeInfoGain(groupData.avg_gpt_response_info_gain),
        responseLength: normalizeResponseLength(groupData.avg_normed_gpt_response_length),
        score: normalizeScore(groupData.avg_score)
      };
      // console.log('groupMetrics:', groupMetrics);

      const angles = {
        infoGain: metricValueScale(groupMetrics.infoGain),
        responseLength: metricValueScale(groupMetrics.responseLength),
        score: metricValueScale(groupMetrics.score)
      };

      function toTwoDecimals(floatNumber) {
        return floatNumber.toFixed(2);
      }
      // Draw the arcs for each metric
      ['infoGain', 'responseLength', 'score'].forEach((metric, i) => {
        // width - margin-right-(innerRadius + arcWidth) * (i) * 1.1*3
        // let XOffset = outerRadius  + (innerRadius + arcWidth) * (i) * 2;    (width-centerx - 3*1.1*(innerRadius + arcWidth)*2
        // let XOffset = width- margin.right  -outerRadius- (innerRadius + arcWidth) * 3*1.1 + (innerRadius + arcWidth) * (i) * 3*1.1;
        let XOffset = width- margin.right -centerX  - (innerRadius + arcWidth) * 5*1.1 + (innerRadius + arcWidth) * (i) * 2*1.1;
        svg.append('path')
          .datum({
            // startAngle: i * (2 * Math.PI / 3), // Each metric gets 1/3 of the circle
            // endAngle: i * (2 * Math.PI / 3) + angles[metric] // End angle based on the normalized value
            startAngle: 0, // Each metric gets 1/3 of the circle
            endAngle: angles[metric] // End angle based on the normalized value
          })
          .attr('d', arcList[i])
          .attr('transform', `translate(${centerX + XOffset}, ${centerY})`)
          .attr('fill', metricColors[i])

        // svg.append('text')
        //   .attr('transform', `translate(${centerX + XOffset - 5}, ${centerY})`)
        //   .text(toTwoDecimals(groupMetrics[metric]))
        //   .attr('font-size', '7.5px');
      });
    };

    // After drawing the donut chart, call drawMetricArcs
    const donutCenterX = margin.left + donutOuterRadius; // Center of the donut chart on the x-axis
    const donutCenterY = margin.top + donutOuterRadius; // Center of the donut chart on the y-axis

    // Assuming the groupData has the required properties like avg_info_gain
    drawMetricArcs(donutCenterX, donutCenterY, donutInnerRadius, donutOuterRadius);

    // Call the function to draw the arcs in the location where you set up the donut chart
    // ...


    //////////////////////////////////////////////////
    // Add an offset equal to twice the donutCenterY before the scale is applied
    const stackedBarOffset = 2 * donutCenterY;
    // Draw bars
    svg.append("g")
      .selectAll("g")
      .data(series)
      .join("g")
      .attr("fill", (d, i) => mapCategoryToColor(groupLabels[i]))
      .selectAll("rect")
      .data(d => d)
      .join("rect")
      .attr("x", d => xScale(d[0]))
      .attr("y", (d, i) => yScale(yDomain[i]) + stackedBarOffset)
      .attr("width", d => xScale(d[1]) - xScale(d[0]))
      .attr("height", yScale.bandwidth())
      .on('click', function (event, d) {
        // Now d has the properties 'group' and 'individual'
        console.log(d.data); // The group this bar belongs to
        console.log(d.data.group); // The group this bar belongs to
        console.log(d.data.individual); // The individual label/category this bar represents
        // Full group data can also be accessed if needed
        onBarClick(d.data.group, d.data.individual); // Call the passed in onBarClick function with the group and individual identifiers
      });


    // Draw the y-axis
    svg.append("g")
      .attr("transform", `translate(${margin.left},${stackedBarOffset})`)
      .call(d3.axisLeft(yScale))
      .selectAll("text") // selects all text elements in the y-axis group
      .style("font-size", "5px"); // sets the font size of the y-axis labels to 10px

    // After drawing the bars, apply highlighting if necessary
    if (isHighlighted) {
      d3.select(svgRef.current)
        .selectAll('rect')
        .style('opacity', 0.5); // Example: dim the chart if not highlighted
    } else {
      d3.select(svgRef.current)
        .selectAll('rect')
        .style('opacity', 1); // Example: full opacity for the highlighted chart
    }




    ////////////////////////////////////////////////////////////////////////////
    // draw the GPT metrics charts


    // New scales for GPT and Score metrics
    const xScaleGPTInfoGain = d3.scaleLinear()
      .domain([d3.min(individualList, d => d.avg_gpt_response_info_gain), d3.max(individualList, d => d.avg_gpt_response_info_gain)])
      .range([0, chartWidth * chartWidthPercentage[1]]); // 20% of the chartWidth for GPT info gain

    const xScaleGPTResponseLength = d3.scaleLinear()
      .domain([d3.min(individualList, d => d.avg_normed_gpt_response_length), d3.max(individualList, d => d.avg_normed_gpt_response_length)])
      .range([0, chartWidth * chartWidthPercentage[2]]); // 20% of the chartWidth for GPT response length

    const xScaleScore = d3.scaleLinear()
      .domain([d3.min(individualList, d => d.avg_score), d3.max(individualList, d => d.avg_score)])
      .range([0, chartWidth * chartWidthPercentage[3]]); // 20% of the chartWidth for average score


   

    // Calculate the starting x position for each set of metric bars
    const extraMetrics = ['avg_gpt_response_info_gain', 'avg_normed_gpt_response_length', 'avg_score'];

    const metricScales = [xScaleGPTInfoGain, xScaleGPTResponseLength, xScaleScore];

    

    // Draw the extra metric bars and labels
    extraMetrics.forEach((metric, index) => {
      const metricScale = metricScales[index];
      // console.log("metricScale here",metricScale)
      const metricData = individualList.map(d => d[metric]);

      // Calculate max width of the current metric's bars to adjust the starting position for the next metric
      const maxMetricWidth = calculateMaxEnd(metricData, metricScale);

      // Draw the bars for the current metric
      svg.selectAll(`.${metric}-bar`)
        .data(metricData)
        .enter()
        .append("rect")
        .attr("class", `${metric}-bar`)
        .attr("x", extraBarsStartX)
        .attr("y", (d, i) => yScale(individualList[i][idKey]) + stackedBarOffset)
        .attr("width", metricScale)
        .attr("height", yScale.bandwidth())
        .attr("fill", metricColors[index]); // Set different shades of grey for each metric if required

      // Add labels for the first metric only
      // if (index === 0) {
      svg.append("text")
        .attr("x", extraBarsStartX + maxMetricWidth / 2 - 5)
        // .attr("y", margin.top - 10)
        .attr("y", donutChartHeight-margin.top) // Adjust the y position as needed
        // .text(metric.toUpperCase().replace(/_/g, ' '))
        .text(metricsNames[index])
        .attr("text-anchor", "middle")
        .style("font-size", "7.5px");
      // }

      // Update the starting x position for the next set of metric bars
      extraBarsStartX += maxMetricWidth + 10; // Add some space between different metrics


      ////////////////////////////////////////////////////////////////////////////
      // draw the donut chart's arcs here, to keep the size of each arc the same
    });



  };

  return (
    <div ref={wrapperRef} style={{ height: '100%', flex: 1 }}>
      <svg ref={svgRef} style={{ width: '100%', height: '100%', border: isHighlighted ? '2px solid red ' : 'none', borderRadius: '15px'  }}></svg>
    </div>
  );
};

export default StackedBarChart;
