import React, { useState, useEffect } from "react";
import {
  fetchStudentConversation,
  fetchTaskInfoStudentAnswer,
} from "../api/api"; // Adjust the import path as necessary

import Header from "./Header";

function ExampleView(props) {
  const [selectedStudentName, setSelectedStudentName] = useState(null);
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const [taskInfo, setTaskInfo] = useState(null);

  const API_BASE_URL = "http://127.0.0.1:5000";
  const studentNameTemp = "luo_zixi";
  const taskIdTemp = "Task1";

  useEffect(() => {

    setSelectedStudentName(props.selectedStudentName);
    setSelectedQuestion(props.selectedQuestion);

    
  }, [props]);



  useEffect(() => {
    if(selectedStudentName === null || selectedQuestion === null) return;
    fetchStudentConversation(selectedStudentName, selectedQuestion)
      .then((data) => {
        console.log("Student conversation:", data);
      })
      .catch((error) =>
        console.error("Failed to fetch student conversation:", error),
      );

    fetchTaskInfoStudentAnswer(selectedStudentName, selectedQuestion)
      .then((data) => {
        console.log("Task information and student answer:", data);
        setTaskInfo(data);
      })
      .catch((error) =>
        console.error("Failed to fetch task information:", error),
      );
  }, [selectedStudentName, selectedQuestion]);

  const questionContent = () => {
    const questionTextArray =
      taskInfo === null
        ? []
        : taskInfo.task_text.split(":").slice(1).join(" ").split("\n");

    return questionTextArray.map((questionText, i) => (
      <p key={i} style={{ margin: "5px" }}>
        {questionText}
      </p>
    ));
  };

  const taskFigureImage = () => {
    if (taskInfo === null) {
      return <span />;
    } else {
      if (taskInfo.task_figure_url === null) return <span />;
      return (
        <div>
          <a
            href={`${API_BASE_URL}/static/images/${taskInfo.task_figure_url}`}
            data-lightbox="group"
          >
            <img
              src={`${API_BASE_URL}/static/images/${taskInfo.task_figure_url}`}
              alt="Task Figure"
              style={{ width: "100%", margin: "10px" }}
            />
          </a>
        </div>
      );
    }
  };

  const studentAnswerContent = () =>
    taskInfo === null ? "" : <p>{taskInfo.student_answer}</p>;

 

  return (
    <div className="example-view" id="question_answer">
      <Header title="Example View" />
      <div style={{ paddingLeft: "10px",  overflowY: "auto" }}>
        <h5 style={{ margin: "5px" }}>Question</h5>
        {questionContent()}
        {taskFigureImage()}
      </div>
      <div
        style={{ padding: "10px", height: "fit-content", overflowY: "auto" }}
      >
        <h5 style={{ margin: "5px" }}>Student Answer</h5>
        {studentAnswerContent()}
      </div>
    </div>
  );
}

export default ExampleView;
