import React, { useEffect, useState, useRef, useCallback } from "react";
import ReactECharts from "echarts-for-react";
import Header from "./Header";
import ColorLegend from './ColorLegend'; // Adjust the path to where you save the component file
import {
  fetchStrategyHierarchy,
  fetchSequenceView,
  fetchSubSequenceData,
  fetchStrategyTreeData,
} from "../api/api";
import { drawTree } from "../charts/createTree";

import { Button } from "antd";
import { ReactTabulator } from "react-tabulator";
import StackedBarChart from "../charts/StackedBarChart";
import SummaryStackedBarChart from "../charts/SummaryStackedBarChart";
// Import ComparisonView at the top
// import ComparisonView from './ComparisonView'; // Adjust the path based on your file structure
const { mapCategoryToColor } = require("../helper/categoryColorMapping");
// function StrategyView({ SelectedQuestionId, setSelectedNodesForComparison }) {
// Helper function to process and aggregate data for ECharts

// Function to format the response data for subsequence table
function formatData(dataList) {
  let id = 1;
  return dataList.map((data) => {
    return {
      id: id++,
      length: data.subsequence.length,
      subsequence: data.subsequence, // Keep as array of objects
      subsequenceVisual: "", // Placeholder for visual representation
      type: data.type, // Placeholder, to be filled later
      support: data.support,
      avg_score: data.avg_score,
      index: data.index,
      students: data.students,
    };
  });
}

function StrategyView(props) {
  const [hierarchyData, setHierarchyData] = useState({});
  const hierarchyDataRef = useRef(hierarchyData); // Create a ref for the latest hierarchyData
  const [chartSize, setChartSize] = useState({ width: 250, height: 250 });
  const updateChartSize = useCallback(() => {
    // For simplicity, we're just using a fixed ratio for height.
    // You can adjust this to be more dynamic based on your needs.
    const newWidth = document.querySelector('.chart-wrapper').clientWidth;
    // const newHeight = newWidth * 0.5; // Example: maintain a 2:1 width:height ratio
    const newHeight = document.querySelector('.chart-wrapper').clientHeight ; // Example: maintain a 2:1 width:height ratio
    setChartSize({ width: newWidth, height: newHeight });
  }, []);

  useEffect(() => {
    // Initial size update
    updateChartSize();
    
    // Update chart size on window resize
    window.addEventListener('resize', updateChartSize);
    return () => window.removeEventListener('resize', updateChartSize);
  }, [updateChartSize]);
  // Update the ref whenever hierarchyData changes
  useEffect(() => {
    hierarchyDataRef.current = hierarchyData;
  }, [hierarchyData]);

  const [tableData, settableData] = useState([]);
  const [checkedNodes, setCheckedNodes] = useState({}); // Tracks checkboxes
  // Correctly initializing state within the component
  const [selectedPaths, setSelectedPaths] = useState([]);
  // const [selectedQuestion, setSelectedQuestion] = useState(null);
  const [selectedTaskGroups, setselectedTaskGroups] = useState(null);
  const [selectedStudentGroups, setselectedStudentGroups] = useState(null);
  const [tableKey, setTableKey] = useState(Date.now());
  // const [selectedNodes, setSelectedNodes] = useState([]); // Tracks selected nodes

  const [mode, setMode] = useState("student"); // 'student' or 'task'
  const [modeData, setModeData] = useState({}); // Data for the selected mode, e.g., props.selectedStudentGroups or props.selectedTaskGroups
  const [summaryData, setSummaryData] = useState({}); // Data for the summary chart, e.g., props.selectedStudentGroups or props.selectedTaskGroups
  const [summaryDonutData, setSummaryDonutData] = useState({}); // Data for the summary chart, e.g., props.selectedStudentGroups or props.selectedTaskGroups
  const [chartOptions, setChartOptions] = useState({});
  const [groupChartsOptions, setGroupChartsOptions] = useState([]);
  const [echartsInstances, setEchartsInstances] = useState([]);
  const [selectedStrategyName, setSelectedStrategyName] = useState(null);
  const [selectedStrategyIndex, setSelectedStrategyIndex] = useState(null);

  // Create a ref for each chart
  // const echartsRefs = useRef([]);
  // const tableRef = useRef(null);

  let ref = React.useRef();
  const [containerSizes, setContainerSizes] = useState([]);
  const [selectedTaskOrStudents, setSelectedTaskOrStudents] = useState([]); // Tracks selected task or students

  const [searchTerm, setSearchTerm] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [highlightedGroup, setHighlightedGroup] = useState(null);


  // Update filteredData whenever tableData or searchTerm changes
  useEffect(() => {
    // Initialize filteredData with tableData on component mount
    if (props.tableData && props.tableData.length > 0) {
      setFilteredData(props.tableData);
    }
  }, [props.tableData]); // Dependency on props.tableData to update when it changes

  useEffect(() => {
    if (searchTerm) {
      // Perform search filtering
      const lowercasedSearchTerm = searchTerm.toLowerCase();
      const filtered = (props.tableData || []).filter((strategy) => {
        // Adjust the property to match the structure of your data
        const strategyName = strategy.name || "";
        return strategyName.toLowerCase().includes(lowercasedSearchTerm);
      });
      setFilteredData(filtered);
    } else {
      // Reset to full list if searchTerm is cleared
      setFilteredData(props.tableData || []);
    }
  }, [searchTerm]); // Dependency only on searchTerm for filtering

  // const handleSearchChange = (event) => {
  //   setSearchTerm(event.target.value);
  // };

  // const handleSearchReset = () => {
  //   setSearchTerm("");
  // };

  // Prepare your data for the summary chart
  // Prepare summary data
  // Assuming modeData is an object where each key is a group name or number
  // and its value contains the group details including label_distribution

  const fixedUniqueCategories = [
    "Remember",
    "Understand",
    "Apply",
    "Analyze",
    "Evaluate",
    "Create",
    "General prompt strategies from literature",
    "Others",
  ];

  function getOverallLabelDistribution(modeDataObject) {
    // Initialize an empty object to accumulate label counts
    const overallDistribution = {};

    // Convert the object's values to an array (if they are not already)
    const groupDataArray = Object.values(modeDataObject);

    // Iterate over each group's data
    groupDataArray.forEach(group => {
      // Ensure there is a label_distribution key in the group object
      if (group.label_distribution && Array.isArray(group.label_distribution)) {
        group.label_distribution.forEach(labelDist => {
          // Aggregate label counts
          overallDistribution[labelDist.label_category] =
            (overallDistribution[labelDist.label_category] || 0) + labelDist.label_count;
        });
      }
    });

    // Convert the accumulated counts to the desired array format
    const overallDistributionArray = Object.entries(overallDistribution).map(([label_category, label_count]) => ({
      label_category,
      label_count
    }));

    return overallDistributionArray;
  }

  // Example usage:
  // const modeData = ...; // Your data object
  // const overallDistribution = getOverallLabelDistribution(modeData);
  // console.log(overallDistribution);


  const prepareSummaryData = (modeData) => {
    return Object.entries(modeData).map(([group, details]) => {
      const { label_distribution, student_list, task_list, category, avg_answer_relevancy
        , avg_gpt_response_info_gain, avg_normed_gpt_response_length, avg_score, average_cs_background, average_gpt_background, average_vis_background, average_task_difficulty } = details;

      const numItems = student_list ? student_list.length : task_list.length;

      // Sort label_distribution according to fixedUniqueCategories
      const sortedLabelDistribution = label_distribution.sort(
        (a, b) =>
          fixedUniqueCategories.indexOf(a.label_category) -
          fixedUniqueCategories.indexOf(b.label_category),
      );

      const categories = sortedLabelDistribution.map((label) => ({
        label: label.label_category,
        average: label.label_count / numItems,

      }));

      const total = categories.reduce((acc, cur) => acc + cur.average, 0);

      // Generate categories with sorted and cumulative values
      const cumulativeCategories = categories.map((cat, index) => ({
        ...cat,
        startValue:
          index === 0
            ? 0
            : categories
              .slice(0, index)
              .reduce((acc, cur) => acc + cur.average, 0),
        endValue:
          index === 0
            ? cat.average
            : categories
              .slice(0, index + 1)
              .reduce((acc, cur) => acc + cur.average, 0),
        group: typeof category === "number" ? `group_${category}` : category, // Pass group identifier for click handling
      }));

      // const tooltipKeys = modeData.student_list ? ['average_cs_background', 'average_gpt_background','average_vis_background','avg_score'] : ['average_task_difficulty', 'avg_score'];
      // console.log("modeData.student_list", modeData);
      return {
        group: typeof category === "number" ? `group_${category}` : category,
        total,
        categories: cumulativeCategories,
        avg_answer_relevancy: avg_answer_relevancy,
        avg_gpt_response_info_gain: avg_gpt_response_info_gain,
        avg_normed_gpt_response_length: avg_normed_gpt_response_length,
        avg_score: avg_score,
        numItems: numItems,
        average_cs_background: average_cs_background,
        average_gpt_background: average_gpt_background,
        average_vis_background: average_vis_background,
        average_task_difficulty: average_task_difficulty,
        student_or_task: modeData["group_0"].student_list ? 'student' : 'task',

      };
    });
  };

  useEffect(() => {
    // Assuming that `groupChartsOptions` dictates the number of containers
    if (groupChartsOptions.length > 0) {
      // Calculate size for each container, for example:
      const newSizes = groupChartsOptions.map((_, index) => {
        // This is a simplification; you would calculate the size based on parent or screen size
        return { width: 100 / groupChartsOptions.length, height: "15vh" };
      });
      setContainerSizes(newSizes);
    }
  }, [groupChartsOptions.length]); // Re-run this effect when the number of charts changes

  useEffect(() => {
    if (
      props.selectedTaskGroups !== null &&
      props.setselectedStudentGroups !== null
    ) {
      console.log("selectedTaskGroups not null", props.selectedTaskGroups);
      console.log(
        "selectedStudentGroups not null",
        props.selectedStudentGroups,
      );
      fetchSequenceView(
        props.selectedTaskGroups,
        props.selectedStudentGroups,
      ).then((data) => {
        console.log("Fetched sequence data:", data); // Add this line to inspect the fetched data

        //////////////////////////////////////////////////////
        //// below is code to draw the stacked bar charts ////
        //////////////////////////////////////////////////////

        const rawData = data; // Replace with the actual prop for raw data
        const selectedModeData = rawData[`selected_${mode}_groups`];
        console.log("modeData", selectedModeData);
        setModeData(selectedModeData);
        const summayDataRaw = prepareSummaryData(selectedModeData);
        const summaryDonutDataRaw = getOverallLabelDistribution(selectedModeData);
        // setSummaryDonutData(summaryDonutDataRaw);
        setSummaryData({ "summayData": summayDataRaw, "summaryDonutData": summaryDonutDataRaw });
      });
    }
  }, [props.selectedTaskGroups, props.selectedStudentGroups, mode]);

  const handleModeChange = (newMode) => {
    setMode(newMode);
  };



  /////////////////////////////
  // handle bar click ///////
  ///////////////////////////
  useEffect(() => {
    console.log("Hierarchy Data Updated:", hierarchyData);
    if (Object.keys(hierarchyData).length === 0) return;

    drawTree(hierarchyData, checkedNodes, selectedStrategyIndex, props, props.handleStudentTagClick); // Assume drawTree is a function to update the tree visualization
  }, [hierarchyData]); // Dependency array ensures this effect runs on hierarchyData changes

  useEffect(() => {
    console.log("mode  Updated:", mode);
  }, [mode]); // Dependency array ensures this effect runs on hierarchyData changes


  // This function will be called when a bar is clicked in the StackedBarChart
  const onBarClick = (group, individual) => {
    // Initialize lists for tasks and students
    console.log("group", group);
    console.log("individual", individual);
    let selected_task_list = [];
    let selected_student_list = [];

    // Check if it's a group bar or an individual bar
    if (group === individual) {
      // It's a group bar
      if (mode === "student") {
        selected_task_list = props.selectedTaskGroups.map(
          (task) => task.task_id,
        ); // include all tasks
        selected_student_list = props.selectedStudentGroups
          .filter(
            (student) =>
              student.category.toString() === group.replace("group_", ""),
          )
          .map((student) => student.student_id);
      } else {
        selected_student_list = props.selectedStudentGroups.map(
          (student) => student.student_id,
        ); // include all students
        selected_task_list = props.selectedTaskGroups
          .filter((task) => task.category === group)
          .map((task) => task.task_id);
      }
    } else {
      // It's an individual bar
      if (mode === "student") {
        selected_task_list = props.selectedTaskGroups.map(
          (task) => task.task_id,
        ); // include all tasks
        selected_student_list = [individual]; // only the selected individual
      } else {
        selected_student_list = props.selectedStudentGroups.map(
          (student) => student.student_id,
        ); // include all students
        selected_task_list = [individual]; // only the selected task
      }
    }
    console.log("selected_task_list", selected_task_list)
    console.log("selected_student_list", selected_student_list)


    // Fetch the data for strategy tree
    fetchStrategyTreeData(selected_task_list, selected_student_list)
      .then((fetchedHierarchyData) => {
        console.log("Fetched strategy hierarchy data:", fetchedHierarchyData); // Add this line to inspect the fetched data
        setHierarchyData(fetchedHierarchyData);
      })
      .catch((error) =>
        console.error("Failed to fetch strategy hierarchy data:", error),
      );

    // Now fetch the data for subsequence table
    fetchSubSequenceData(selected_task_list, selected_student_list)
      .then((subdata) => {
        console.log("Fetched subsequence data:", subdata); // Add this line to inspect the fetched data
        settableData(formatData(subdata));
        // setTableKey(Date.now()); // Update key to force re-render
      })
      .catch(error => console.error('Failed to fetch subsequence data:', error));
  };


  ///////////////////////////////////////////////////////////////////////
  /// new function to draw the stacked bar chart and donut hole chart ///
  ///////////////////////////////////////////////////////////////////////


  /////////////////////////////////////////////////////////////
  /// function to draw the main strategy visualization tree ///
  /////////////////////////////////////////////////////////////

  const generateSubsequenceVisualization = (subsequence, mapCategoryToColor, type) => {
    if (!subsequence || !Array.isArray(subsequence)) return '';
  
    // 开始一个容器用于在垂直方向展开
    let visualizationHTML = `<div class="subsequence-visualization" style="display: block;">${type === 'set' ? '{ ' : ''}`;
  
    // 遍历子序列中的每个节点以创建视觉元素
    subsequence.forEach((node, index) => {
      // 检查节点是否为[code, category]数组
      if (Array.isArray(node) && node.every(item => Array.isArray(item))) {
        // 当节点是数组时，遍历其元素([code, category])
        node.forEach(([code, category]) => {
          // 获取类别对应的颜色
          const color = mapCategoryToColor(category) || 'lightgray';
          // 创建带有颜色背景的代码的视觉表示
          visualizationHTML += `<div class="node" style="background-color: ${color}; display: block; padding: 2px 4px; margin: 4px 0; border-radius: 4px;">${code}</div>`;
        });
      } else {
        // 当节点不是数组时，它是单个[code, category]
        const [codeName, category] = node;
        // 获取类别对应的颜色
        const color = mapCategoryToColor(category) || 'lightgray';
        // 创建节点的视觉表示
        visualizationHTML += `<div class="node" style="background-color: ${color}; display: block; padding: 2px 4px; margin: 4px 0; border-radius: 4px;">${codeName}</div>`;
      }
  
      // 根据类型和位置添加连接器或闭合括号
      if (type === 'ordered' && index < subsequence.length - 1) {
        visualizationHTML += '<div class="link" style="display: block; padding: 2px 4px;">→</div>';
      } else if (type === 'set' && index === subsequence.length - 1) {
        visualizationHTML += ' }';
      }
    });
  
    // 结束容器div
    visualizationHTML += '</div>';
  
    return visualizationHTML;
  };
  






  // Function to draw the table
  const subsequenceFormatter = (cell, formatterParams, onRendered) => {
    const subsequence = cell.getValue();
    const strategyIndex = cell.getRow().getData().index;
    const strategyData = cell.getRow().getData();
    // console.log("strategyData here", strategyData);
    // Logic to generate SVG/HTML representation goes here
    // Use mapCategoryToColor to assign colors to nodes based on category
    // Return an SVG/HTML string
    return generateSubsequenceVisualization(subsequence, mapCategoryToColor, strategyData.type);
  };

  // const updateTreeVisualization = (updatedTreeData,strategyIndex) => {
  //   // This function will update the tree visualization based on the selected strategy index
  //   // Assume that drawTree is a function you have that draws the tree and accepts an optional parameter to highlight specific nodes or paths

  //     drawTree(updatedTreeData, checkedNodes, strategyIndex,props,props.handleStudentTagClick); // Highlight paths using the strategy index

  // };

  // Define the row click handler outside your component render

  // const handleRowClicked = useCallback((e, cell) => {
  //   // Function body remains the same
  //   // Get the table instance from the ref
  //   const table = cell.getTable();
  //   // Get all table rows
  //   const rows = table.getRows();

  //   // Find if the clicked row still exists in the table
  //   const rowExists = rows.find(r => r === cell.getRow());

  //   if (rowExists) {
  //     const strategyIndex = cell.getRow().getData().index;
  //     // console.log("strategyIndex in row", strategyIndex);
  //     //  let updatedTreeData =  hierarchyData;
  //     //  updateTreeVisualization(updatedTreeData,strategyIndex);
  //     drawTree(hierarchyData, checkedNodes, strategyIndex, props, props.handleStudentTagClick);
  //   } else {
  //     console.error('The row this cell is attached to cannot be found.');
  //   }
  // }, [hierarchyData]); // Include all dependencies here

  const handleRowClicked = useCallback((e, cell) => {
    // Access hierarchyData from the ref
    const currentHierarchyData = hierarchyDataRef.current;

    // Now use currentHierarchyData which is always up-to-date
    const table = cell.getTable();
    const rows = table.getRows();
    const rowExists = rows.find(r => r === cell.getRow());

    if (rowExists) {
      const strategyIndex = cell.getRow().getData().index;
      // Use drawTree with the most current hierarchyData
      drawTree(currentHierarchyData, checkedNodes, strategyIndex, props, props.handleStudentTagClick);
    } else {
      console.error('The row this cell is attached to cannot be found.');
    }
  }, [props]); // Update the dependency array as needed


  const columns = [
    {
      title: "Length",
      field: "length",
      sorter: "number",
      width: "5%", // Adjust width as needed to make the column smaller
      hozAlign: "center",
      // tooltip: true, // Show tooltip on hover
    },

    {
      title: "Strategy",
      field: "subsequence",
      formatter: subsequenceFormatter,
      cellClick: handleRowClicked,
      width: "50%", // Adjust width as needed to make the column smaller
      // cellClick: (e, cell) => {
      //   const strategyIndex = cell.getRow().getData().index;
      //   // Update the tree visualization here
      //   updateTreeVisualization(strategyIndex);
      // }
    },
    {
      title: "Support",
      field: "support",
      sorter: "number",
      width: "5%", // Adjust width as needed to make the column smaller
      hozAlign: "center",
      // tooltip: true, // Show tooltip on hover
    },
    {
      title: "avg_score",
      field: "avg_score",
      // sorter: "number",
      width: "20%", // Adjust width as needed to make the column smaller
      hozAlign: "center",
      // tooltip: true, // Show tooltip on hover
    },
  ];

  ///////////////////////////////////////////////
  //// enable the search for the subsequence ///
  ///////////////////////////////////////////////

  const onSelectChange = (e) => {
    // Get the table instance from the ref
    const nameFilter = e.target.value;
    setSelectedStrategyName(nameFilter);
  };

  const searchForCodeInSubsequence = (subsequence, code) => {
    // Check if the code is present in the subsequence
    return subsequence.some(node => {
      // If the node itself is an array, check each element inside it
      if (Array.isArray(node[0])) {
        return node.some(element => element[0] === code);
      } else {
        // If the node is not an array, check its code directly
        return node[0] === code;
      }
    });
  };

  const searchedData = selectedStrategyName
    ? tableData.filter(item => searchForCodeInSubsequence(item.subsequence, selectedStrategyName))
    : tableData;
  console.log("searchedData", searchedData);

  return (
    <div
      className="strategy-view"
      style={{ height: "100%", display: "flex", flexDirection: "column" }}
    >
      <Header title="Strategy View" />
      <div className="upper-zone" style={{ height: '35%', width: '100%', display: 'flex', flexDirection: 'column' }}>
        {/* <h2>Strategy View</h2> */}

        <div className="controls-container" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px' }}>
          <div className="mode-buttons">
            <Button
              type="primary"
              size="small"
              onClick={() => handleModeChange("student")}
            >
              StudentG
            </Button>
            <Button
              type="primary"
              size="small"
              onClick={() => handleModeChange("task")}
            >
              TaskG
            </Button>
          </div>
          <ColorLegend colors={['#FFD580', '#FFB347', '#FF8C00', '#E67300', '#CC6600', '#995C00', '#779ECB', '#A0DAA9']} />
        </div>
        <div className="chart-wrapper" style={{ flex: 1, display: 'flex', flexDirection: 'row', justifyContent: 'space-between', }}>
          <div style={{ display: 'flex', width: '20%',background: '#f0f0f0',  padding: '5px',margin:10, background: '#f0f0f0', borderRadius: '15px',}}>
            <SummaryStackedBarChart
              data={summaryData}
              width={215}
              height={chartSize.height}
              onGroupClick={setHighlightedGroup}
            />
          </div>
          {Object.values(modeData).map((group, index) => (
            <div
              key={index}
              className="strategy-chart-container"
              style={{ width: "100%", height: "100%" }}
            >
              <StackedBarChart
                groupData={group}
                width={500} // Assuming fixed width, adjust as necessary
                height={chartSize.height}
                isHighlighted={highlightedGroup === (typeof group.category === 'number' ? `group_${group.category}` : group.category)}
                onBarClick={onBarClick} // Pass the callback function as a prop
              />
            </div>
          ))}
        </div>
      </div>

      <div className="middle-zone" style={{ height: '65%', width:'100%', display: 'flex', flexDirection: 'row' }}>

        <div className="table-obj" style={{ width: '33%', height: '100%', display: 'flex', flexDirection: 'column',overflow: 'hidden' }}>

          <div className="table-header" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '10px', marginLeft: '2.5%' }}>
            {/* Select element is now part of the table header */}
            <select style={{ height: '30px' }} onChange={onSelectChange}>
              <option value="">Select Strategy</option>
              <option value="Definition Inquiry">Definition Inquiry</option>
              <option value="Learning Recommendation">Learning Recommendation</option>
              <option value="Technique Inquiry">Technique Inquiry</option>
              <option value="Question Inquiry">Question Inquiry</option>
              <option value="Elaboration with Example">Elaboration with Example</option>
              <option value="Elaboration with Figure">Elaboration with Figure</option>
              <option value="General Elaboration">General Elaboration</option>
              <option value="Purpose Inquiry">Purpose Inquiry</option>
              <option value="Application Inquiry">Application Inquiry</option>
              <option value="Concept Comparison">Concept Comparison</option>
              <option value="Follow up Questions">Follow up Questions</option>
              <option value="Ask for Guidelines">Ask for Guidelines</option>
              <option value="Critiquing ​​Questioning">Critiquing ​​Questioning</option>
              <option value="Hypothetical Questioning">Hypothetical Questioning</option>
              <option value="Learning Materials Creation">Learning Materials Creation</option>
              <option value="Output Restriction">Output Restriction</option>
              <option value="Persona">Persona</option>
              <option value="Provide Example">Provide Example</option>
              <option value="Background Checking">Background Checking</option>
              <option value="Workflow Description">Workflow Description</option>
              <option value="Repeated Prompt">Repeated Prompt</option>
              <option value="Prompt Clarification">Prompt Clarification</option>
              <option value="Fact Verification">Fact Verification</option>
              <option value="Use Other Resources">Use Other Resources</option>
              <option value="GPT Feasibility Inquiry">GPT Feasibility Inquiry</option>
              <option value="Empty">Empty</option>
              <option value="Default System Prompt">Default System Prompt</option>
            </select>
            {/* Add other header elements if necessary */}
          </div>
          <div className="subsequence-table" style={{ flex: '1 1 auto', overflowX: 'auto', marginLeft: '2.5%'  /* Show horizontal scrollbar if needed */}}>
            <ReactTabulator
              onRef={(r) => (ref = r)}
              // key={tableKey}
              data={searchedData}
              columns={columns}
              layout={"fitColumns"}
              options={{
                movableColumns: true,
                movableRows: true,
                resizableRows: true,
                responsiveLayout: "collapse",
                // marginLeft: 20,
                // height: "90%",
                // width: "50%",
                // tooltips: true,
                pagination: "local", // enable pagination
                paginationSize: 8,  // set number of rows per page
                paginationButtonCount: 10, // set maximum page number buttons
                // height: "400px", // fixed height for table
                virtualDom: true, // enable virtual DOM to improve performance on large datasets
              }}
            />
          </div>
        </div>

        {/* <div style={{ flex: '1 1 16%' }}>
        </div> */}
        {/* <div className="tree" style={{ flex: "2 1 66%", height: '100%', width: '100%' }}></div> */}
        <div className="tree" style={{ height: '100%', width: '66%' }}></div>
        {/* <Button
            type="primary"
            size="small"
            id="expand-all-button"
          ></Button> */}
      </div>
    </div>
  );
}

export default StrategyView;
