import React, { useEffect, useState } from "react";
import { fetchConversationsForTask } from "./api/api"; // Import the API call
import "./App.css";

import "react-tabulator/css/bootstrap/tabulator_bootstrap.min.css";
import "react-tabulator/lib/styles.css";

import ActionView from "./views/ActionView";
import ConversationView from "./views/ConversationView";
import ComparisonView from "./views/ComparisonView";
import QuestionView from "./views/QuestionView";
import SequenceView from "./views/SequenceView";
import StrategyView from "./views/StrategyView";
import ExampleView from "./views/ExampleView";
import TopicView from "./views/TopicView";
import { select } from "d3";

function App() {
  // State to store the conversation data for task1
  const [conversations, setConversations] = useState([]);
  const [selectedQuestionCategory, setSelectedQuestionCategory] =
    useState(null);
  const [selectedTaskGroups, setselectedTaskGroups] = useState(null);
  const [selectedStudentGroups, setselectedStudentGroups] = useState(null);
  // const [selectedQuestion, setSelectedQuestion] = useState({
  //   question_id: "task1",
  // });
  const [selectedQuestion, setSelectedQuestion] = useState("Task1");
  const [selectedStudentName, setSelectedStudentName] =
    useState("cheng_junfei");
  const [selectedNodesForComparison, setSelectedNodesForComparison] = useState(
    [],
  );

  const handleSelectedTaskGroups = (d) => {
    console.log("Selected task groups:", d);
    setselectedTaskGroups(d);
  };

  const handleSelectedStudentGroups = (d) => {
    console.log("Selected student groups:", d);
    setselectedStudentGroups(d);
  };

  const handleSelectedQuestionCategory = (d) => {
    setSelectedQuestionCategory(d);
  };

  const handleSelectedQuestion = (d) => {
    setSelectedQuestion({question_id:d});
    console.log("Selected question:", selectedQuestion);
  };

  const handleSelectedStudent = (d) => {
    setSelectedStudentName(d);
    console.log("Selected student:", selectedStudentName);
  };

  const handleStudentTagClick = (studentName, taskId) => {
    console.log("Student tag clicked:", studentName, taskId);
    // Handle the event, e.g., update state or perform an action
    setSelectedStudentName(studentName);
    setSelectedQuestion(taskId);
  };
  // const selectedTask = "Task2"

  // Function to add or remove items from the selectedNodesForComparison array
  const handleSelectedNodesForComparison = (item, action) => {
    if (action === "add") {
      // Add the item if it's not already in the array
      setSelectedNodesForComparison((prevItems) => {
        if (!prevItems.includes(item)) {
          return [...prevItems, item];
        } else {
          return prevItems; // Return the original array if the item is already included
        }
      });
    } else if (action === "delete") {
      // Remove the item from the array
      setSelectedNodesForComparison((prevItems) =>
        prevItems.filter((prevItem) => prevItem !== item),
      );
    }
  };

  // useEffect(() => {
  //   // Use the imported API function to fetch data
  //   fetchConversationsForTask('selectedQuestion')
  //     .then((data) => setConversations(data))
  //     .catch((error) => {
  //       console.error("Error fetching conversations:", error);
  //       setConversations([]); // Set to empty array on error
  //     });

  //   console.log("Selected nodes for comparison:", selectedNodesForComparison);
  // }, [selectedNodesForComparison, selectedQuestion]); // Dependency array is empty, so this effect runs once on mount

  console.log("Selected question:", selectedQuestion);
  return (
    <div className="App">
      <header className="app-header">
        <h1>InteractiV</h1>
      </header>
      <div className="views-container">
        <QuestionView handleSelectedTaskGroups={handleSelectedTaskGroups} />
        {/* <QuestionView handleSelectedQuestionCategory={handleSelectedQuestionCategory} handleSelectedQuestion={handleSelectedQuestion}/> */}
        {/* <ActionView selectedQuestion={selectedQuestion} selectedStudentName={selectedStudentName}/> */}
        <ActionView handleSelectedStudentGroups={handleSelectedStudentGroups} />
        {/* <SequenceView selectedQuestions={selectedQuestion} selectedStudentFileBase={null}/>  */}
        {/* Temporarily set selectedStudentFileBase to null */}
        <StrategyView
          selectedTaskGroups={selectedTaskGroups}
          selectedStudentGroups={selectedStudentGroups}
          selectedQuestion={selectedQuestion}
          setSelectedNodesForComparison={handleSelectedNodesForComparison}
          handleStudentTagClick={handleStudentTagClick}
          // handleSelectedQuestion={handleSelectedQuestion}
        />
        {/* <ComparisonView selectedNodes={selectedNodesForComparison} /> */}
        <ExampleView selectedStudentName={selectedStudentName}
          selectedQuestion={selectedQuestion}/>
        <ConversationView
          selectedStudentName={selectedStudentName}
          selectedQuestion={selectedQuestion}
        />
      </div>
    </div>
  );
}

export default App;
