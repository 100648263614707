import * as d3 from 'd3';

export function slider_cate(layout = {
    width: 300,
    height: 50,
    margin: {
        top: 15,
        bottom: 15,
        left: 20,
        right: 10
    }
},svgContainer, categoreies, [min, max],[starting_min = min, starting_max = max], criteriaKey, setCriteria) {
    // ensure that the number of integer in [min, max] is the same as the number of categories 
    // here we just do a simple mapping

    const range = [min, max];
    const starting_range = [starting_min, starting_max];

    const width = layout.width - layout.margin.left - layout.margin.right;
    const height = layout.height - layout.margin.top - layout.margin.bottom;

    const x = d3.scaleLinear()
        .domain([min, max]) // data space
        .range([0, width]); // display space

    const svg = d3.select(svgContainer)
        .attr('width', layout.width)
        .attr('height', layout.height);

    const g = svg.selectAll('g').data([null]);
    const gEnter = g.enter().append('g').attr('transform', `translate(${layout.margin.left}, ${layout.margin.top})`);

    // Draw background lines
    gEnter.append('g').attr('class', 'grid')
        .selectAll('line')
        .data(d3.range(range[0], range[1] + 1))
        .enter()
        .append('line')
        .attr('x1', d => x(d)).attr('x2', d => x(d))
        .attr('y1', 0).attr('y2', height)
        .style('stroke', '#ccc');

    // Labels
    var labelL = gEnter.append('text')
        .attr('id', 'labelleft')
        .attr('x', x(starting_range[0]))
        .attr('y', height + 5)
        .text(categoreies[0])
        .attr('font-size', 10);

    var labelR = gEnter.append('text')
        .attr('id', 'labelright')
        .attr('x', x(starting_range[1]))
        .attr('y', height + 5)
        .text(categoreies[categoreies.length - 1])
        .attr('font-size', 10);

    // Define brush
    var brush = d3.brushX()
        .extent([[0, 0], [width, height]])
        .on('brush', brushed)
        .on('end', brushended);

    // Append brush to g
    var gBrush = gEnter.append("g")
        .attr("class", "brush")
        .call(brush)
        .call(brush.move, starting_range.map(x));

    // Brush handles resize path
    function brushResizePath(d) {
        var e = +(d.type === "e"),
            x = e ? 1 : -1,
            y = height / 2;
        return `M${0.5 * x},${y}
                A6,6 0 0 ${e} ${6.5 * x},${y + 6}
                V${2 * y - 6}
                A6,6 0 0 ${e} ${0.5 * x},${2 * y}
                Z
                M${2.5 * x},${y + 8}
                V${2 * y - 8}
                M${4.5 * x},${y + 8}
                V${2 * y - 8}`;
    }

    var handle = gBrush.selectAll(".handle--custom")
        .data([{type: "w"}, {type: "e"}])
        .enter().append("path")
        .attr("class", "handle--custom")
        .attr("stroke", "#000")
        .attr("cursor", "ew-resize")
        .attr("d", brushResizePath)
        .attr("fill", '#eee');

    function brushed(event) {
        if (!event.sourceEvent) return; // Only transition after input.
        const selection = event.selection || x.range();
        labelL.attr('x', selection[0])
            .text(categoreies[Math.round(x.invert(selection[0]))-1]);
        labelR.attr('x', selection[1])
            .text(categoreies[Math.round(x.invert(selection[1]))-1]);
        handle.attr("display", null).attr("transform", function(d, i) { return "translate(" + [selection[i], - height / 4] + ")"; });
    }

    function brushended(event) {
        if (!event.sourceEvent) return; // Only transition after input.
        var d0 = event.selection.map(x.invert);
        var d1 = d0.map(Math.round);
        // d3.select(this).transition().call(brush.move, d1.map(x));
        // Transition to the snapped positions
    d3.select(this).transition().call(brush.move, d1.map(x)).on('end', () => {
        // Update label and handle positions after the transition
        labelL.attr('x', x(d1[0]))
            .text(categoreies[d1[0] - 1]);
        labelR.attr('x', x(d1[1]))
            .text(categoreies[d1[1] - 1]);
        handle.attr("transform", function(d, i) { return "translate(" + [x(d1[i]), -height / 4] + ")"; });
    });
                // Completing the brushended function
        setCriteria((c) => {
            const currentRange = c[criteriaKey].map(Math.round);
            // console.log("d1", d1)
            // const category_range = d1.map(v => categoreies[v-1]);
            // console.log("category_range", category_range)
            const startIndex = d1[0] - 1; // because JavaScript arrays are zero-indexed
            const endIndex = d1[1]; // slice method goes up to but not including the end index
            const category_range = categoreies.slice(startIndex, endIndex);
            if (d1[0] === currentRange[0] && d1[1] === currentRange[1]) return c; // No update if the range is unchanged
            return { ...c, [criteriaKey]: category_range };
        });
    }

    // Additional details to ensure full functionality
    // Adding the brushcentered functionality for handling clicks outside the brush area
    gBrush.selectAll(".overlay")
        .each(function() { d3.select(this).style("pointer-events", "all"); })
        .on("mousedown touchstart", brushcentered);

    function brushcentered(event) {
        const dx = x(1) - x(0); // fixed width for re-centering
        const [mx] = d3.pointer(event);
        const x0 = mx - dx / 2;
        const x1 = mx + dx / 2;
        const selection = x1 > width ? [width - dx, width] : x0 < 0 ? [0, dx] : [x0, x1];
        gBrush.call(brush.move, selection.map(x));
    }
    
    // Initially position the brush
    gBrush.call(brush.move, starting_range.map(x));

    return svg.node();
}
export function slider_snap(layout = {
    width: 300,
    height: 50,
    margin: {
        top: 15,
        bottom: 15,
        left: 20,
        right: 10
    }
},svgContainer, [min, max], [starting_min = min, starting_max = max], criteriaKey, setCriteria) {
    

    const range = [min, max];
    const starting_range = [starting_min, starting_max];

    const width = layout.width - layout.margin.left - layout.margin.right;
    const height = layout.height - layout.margin.top - layout.margin.bottom;

    const x = d3.scaleLinear()
        .domain([min, max]) // data space
        .range([0, width]); // display space

    const svg = d3.select(svgContainer)
        .attr('width', layout.width)
        .attr('height', layout.height);

    const g = svg.selectAll('g').data([null]);
    const gEnter = g.enter().append('g').attr('transform', `translate(${layout.margin.left}, ${layout.margin.top})`);

    // Draw background lines
    gEnter.append('g').attr('class', 'grid')
        .selectAll('line')
        .data(d3.range(range[0], range[1] + 1))
        .enter()
        .append('line')
        .attr('x1', d => x(d)).attr('x2', d => x(d))
        .attr('y1', 0).attr('y2', height)
        .style('stroke', '#ccc');

    // Labels
    var labelL = gEnter.append('text')
        .attr('id', 'labelleft')
        .attr('x', x(starting_range[0]))
        .attr('y', height + 5)
        .text(starting_range[0])
        .attr('font-size', 10);

    var labelR = gEnter.append('text')
        .attr('id', 'labelright')
        .attr('x', x(starting_range[1]))
        .attr('y', height + 5)
        .text(starting_range[1])
        .attr('font-size', 10);

    // Define brush
    var brush = d3.brushX()
        .extent([[0, 0], [width, height]])
        .on('brush', brushed)
        .on('end', brushended);

    // Append brush to g
    var gBrush = gEnter.append("g")
        .attr("class", "brush")
        .call(brush)
        .call(brush.move, starting_range.map(x));

    // Brush handles resize path
    function brushResizePath(d) {
        var e = +(d.type === "e"),
            x = e ? 1 : -1,
            y = height / 2;
        return `M${0.5 * x},${y}
                A6,6 0 0 ${e} ${6.5 * x},${y + 6}
                V${2 * y - 6}
                A6,6 0 0 ${e} ${0.5 * x},${2 * y}
                Z
                M${2.5 * x},${y + 8}
                V${2 * y - 8}
                M${4.5 * x},${y + 8}
                V${2 * y - 8}`;
    }

    var handle = gBrush.selectAll(".handle--custom")
        .data([{type: "w"}, {type: "e"}])
        .enter().append("path")
        .attr("class", "handle--custom")
        .attr("stroke", "#000")
        .attr("cursor", "ew-resize")
        .attr("d", brushResizePath)
        .attr("fill", '#eee');

    function brushed(event) {
        if (!event.sourceEvent) return; // Only transition after input.
        const selection = event.selection || x.range();
        labelL.attr('x', selection[0])
            .text(Math.round(x.invert(selection[0])));
        labelR.attr('x', selection[1])
            .text(Math.round(x.invert(selection[1])));
        handle.attr("display", null).attr("transform", function(d, i) { return "translate(" + [selection[i], - height / 4] + ")"; });
    }

    function brushended(event) {
        if (!event.sourceEvent) return; // Only transition after input.
        var d0 = event.selection.map(x.invert);
        var d1 = d0.map(Math.round);
        // d3.select(this).transition().call(brush.move, d1.map(x));
        // Transition to the snapped positions
    d3.select(this).transition().call(brush.move, d1.map(x)).on('end', () => {
        // Update label and handle positions after the transition
        labelL.attr('x', x(d1[0]))
            .text(d1[0]);
        labelR.attr('x', x(d1[1]))
            .text(d1[1]);
        handle.attr("transform", function(d, i) { return "translate(" + [x(d1[i]), -height / 4] + ")"; });
    });
                // Completing the brushended function
        setCriteria((c) => {
            const currentRange = c[criteriaKey].map(Math.round);
            if (d1[0] === currentRange[0] && d1[1] === currentRange[1]) return c; // No update if the range is unchanged
            return { ...c, [criteriaKey]: d1 };
        });
    }

    // Additional details to ensure full functionality
    // Adding the brushcentered functionality for handling clicks outside the brush area
    gBrush.selectAll(".overlay")
        .each(function() { d3.select(this).style("pointer-events", "all"); })
        .on("mousedown touchstart", brushcentered);

    function brushcentered(event) {
        const dx = x(1) - x(0); // fixed width for re-centering
        const [mx] = d3.pointer(event);
        const x0 = mx - dx / 2;
        const x1 = mx + dx / 2;
        const selection = x1 > width ? [width - dx, width] : x0 < 0 ? [0, dx] : [x0, x1];
        gBrush.call(brush.move, selection.map(x));
    }
    
    // Initially position the brush
    gBrush.call(brush.move, starting_range.map(x));

    return svg.node();
}

export function slider(layout = {
    width: 300,
    height: 50,
    margin: {
        top: 15,
        bottom: 15,
        left: 20,
        right: 10
    }
},svgContainer, [min, max], [starting_min = min, starting_max = max], criteriaKey, setCriteria){

    const range = [min, max];
    const starting_range = [starting_min, starting_max];

    const width = layout.width - layout.margin.left - layout.margin.right;
    const height = layout.height - layout.margin.top - layout.margin.bottom;

    const x = d3.scaleLinear()
        .domain([min, max]) // data space
        .range([0, width]); // display space

    const svg = d3.select(svgContainer)
        .attr('width', layout.width)
        .attr('height', layout.height);

    const g = svg.selectAll('g').data([null]);
    const gEnter = g.enter().append('g').attr('transform', `translate(${layout.margin.left}, ${layout.margin.top})`);

    // Draw background lines
    gEnter.append('g').attr('class', 'grid')
        .selectAll('line')
        .data(d3.range(range[0], range[1] + 1))
        .enter()
        .append('line')
        .attr('x1', d => x(d)).attr('x2', d => x(d))
        .attr('y1', 0).attr('y2', height)
        .style('stroke', '#ccc');

    // Labels
    var labelL = gEnter.append('text')
        .attr('id', 'labelleft')
        .attr('x', x(starting_range[0]))
        .attr('y', height + 5)
        .text(starting_range[0])
        .attr('font-size', 10);

    var labelR = gEnter.append('text')
        .attr('id', 'labelright')
        .attr('x', x(starting_range[1]))
        .attr('y', height + 5)
        .text(starting_range[1])
        .attr('font-size', 10);

    // Define brush
    var brush = d3.brushX()
        .extent([[0, 0], [width, height]])
        .on('brush', brushed)
        .on('end', brushended);

    // Append brush to g
    var gBrush = gEnter.append("g")
        .attr("class", "brush")
        .call(brush)
        .call(brush.move, starting_range.map(x));

    // Brush handles resize path
    function brushResizePath(d) {
        var e = +(d.type === "e"),
            x = e ? 1 : -1,
            y = height / 2;
        return `M${0.5 * x},${y}
                A6,6 0 0 ${e} ${6.5 * x},${y + 6}
                V${2 * y - 6}
                A6,6 0 0 ${e} ${0.5 * x},${2 * y}
                Z
                M${2.5 * x},${y + 8}
                V${2 * y - 8}
                M${4.5 * x},${y + 8}
                V${2 * y - 8}`;
    }

    
    var handle = gBrush.selectAll(".handle--custom")
        .data([{type: "w"}, {type: "e"}])
        .enter().append("path")
        .attr("class", "handle--custom")
        .attr("stroke", "#000")
        .attr("cursor", "ew-resize")
        .attr("d", brushResizePath)
        .attr("fill", '#eee');

        function brushed(event) {
            if (!event.sourceEvent) return; // Only transition after input.
            const selection = event.selection || x.range();
            labelL.attr('x', selection[0])
                .text((x.invert(selection[0])).toFixed(2));
            labelR.attr('x', selection[1])
                .text((x.invert(selection[1])).toFixed(2));
            handle.attr("display", null).attr("transform", function(d, i) { return "translate(" + [selection[i], - height / 4] + ")"; });
        }
        

        function brushended(event) {
            if (!event.sourceEvent) return; // Only transition after input.
            var d0 = event.selection.map(x.invert);
            var d1 = d0; // remove the rounding here to keep the precise selection
            d3.select(this).transition().call(brush.move, d1.map(x));
                    // Completing the brushended function
            setCriteria((c) => {
                const currentRange = c[criteriaKey];
                if (d1[0].toFixed(2) === currentRange[0].toFixed(2) && d1[1].toFixed(2) === currentRange[1].toFixed(2)) return c; // No update if the range is unchanged
                return { ...c, [criteriaKey]: d1.map(v => +v.toFixed(2)) };
            });
        }
        

    // Additional details to ensure full functionality
    // Adding the brushcentered functionality for handling clicks outside the brush area
    gBrush.selectAll(".overlay")
        .each(function() { d3.select(this).style("pointer-events", "all"); })
        .on("mousedown touchstart", brushcentered);

    function brushcentered(event) {
        const dx = x(1) - x(0); // fixed width for re-centering
        const [mx] = d3.pointer(event);
        const x0 = mx - dx / 2;
        const x1 = mx + dx / 2;
        const selection = x1 > width ? [width - dx, width] : x0 < 0 ? [0, dx] : [x0, x1];
        gBrush.call(brush.move, selection.map(x));
    }
    
    // Initially position the brush
    gBrush.call(brush.move, starting_range.map(x));

    return svg.node();
}

// backup code for previous slider 
// const createRangeSlider = (svgContainer, [min, max], criteriaKey) => {
//     const margin = { top: 10, right: 30, bottom: 10, left: 30 };
//     const width = 300 - margin.left - margin.right; // might be adjusted based on screen size
//     const height = 50 - margin.top - margin.bottom;
  
//     const svg = d3.select(svgContainer)
//       .attr("width", width + margin.left + margin.right)
//       .attr("height", height + margin.top + margin.bottom);
  
//     const x = d3.scaleLinear().domain([min, max]).range([0, width]);
  
//     // Check if the brush is already created
//     let brush = svg.selectAll(".brush").data([0]);
//     const brushExists = !brush.empty();
  
//     if (!brushExists) {
//       brush = d3.brushX()
//         .extent([[0, 0], [width, height]])
//         .on("end", brushended);
  
//       svg.append("g")
//         .attr("class", "brush") // Add a class to reference later
//         .attr("transform", "translate(" + margin.left + "," + margin.top + ")")
//         .call(brush)
//         .call(brush.move, x.range());
//     }
  
//     function brushended({ selection }) {
//       if (!selection) {
//         brush.call(brush.move, x.range());
//         return;
//       }
//       const [x0, x1] = selection.map(x.invert).map(Math.round);
//       setCriteria(c => {
//         const currentRange = c[criteriaKey].map(Math.round);
//         if (x0 === currentRange[0] && x1 === currentRange[1]) return c; // No update if the range is unchanged
//         return { ...c, [criteriaKey]: [x0, x1] };
//       });
//     }
//   };
  