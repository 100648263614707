import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';

const ColorLegend = ({ colors }) => {
  const d3Container = useRef(null);
  const CategoryColorNames = [
    "Remember",
    "Understand",
    "Apply",
    "Analyze",
    "Evaluate",
    "Create",
    "Prompt strategies",
    "Others"
   ]
  useEffect(() => {
    if (colors && d3Container.current) {
      const svg = d3.select(d3Container.current);

      // Clear the previous legend
      svg.selectAll('*').remove();

      // Set dimensions and margins for the legend
      const margin = { top: 10, right: 15, bottom: 10, left: 15 };
      const width = 500 - margin.left - margin.right;
      const height = 50 - margin.top - margin.bottom;

      // Create a group element for the legend elements
      const g = svg.append('g')
                   .attr('transform', `translate(${margin.left},${margin.top})`);

      // Create rectangles for each color
      colors.forEach((color, i) => {
        g.append('rect')
         .attr('x', i * 50) // Adjust spacing between squares
         .attr('y', 0)
         .attr('width', 20) // Adjust the size of the squares
         .attr('height', 20)
         .style('fill', color);
      });

      // Add labels for each color
      colors.forEach((color, i) => {
        g.append('text')
         .attr('x', i * 50 + 10) // Center the text in the squares
         .attr('y', 35) // Position the text below the squares
         .style('fill', color)
         .style('font-size', '7.5px')
         .style('text-anchor', 'middle')
         .text(CategoryColorNames[i]);
      });
    }
  }, [colors]); // Run the effect whenever the colors change

  return (
    <svg
      className="color-legend"
      width="400" // Set the width of the legend container
      height="50" // Set the height of the legend container
      ref={d3Container}
    />
  );
};

export default ColorLegend;
