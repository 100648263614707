import * as d3 from 'd3';
export function histogram(svgContainer, data, {
  chartTitle, // the title of the chart
  title, // Function to return the title text for each bar, if needed
  marginTop = 20,
  marginRight = 10,
  marginBottom = 20,
  marginLeft = 20,
  labelFontSize = 10,
  width = 960,
  height = 500,
  color = "#888888"
}) {
  // Compute the x-domain based on the bin start and end values.
  const xDomain = [d3.min(data, d => d.bin_start), d3.max(data, d => d.bin_end)];
  
  // Compute the y-domain based on the counts.
  const yDomain = [0, d3.max(data, d => d.count)];

  // Declare the x (horizontal position) scale.
  const xScale = d3.scaleLinear()
      .domain(xDomain)
      .range([marginLeft, width - marginRight])


  // Declare the y (vertical position) scale.
  const yScale = d3.scaleLinear()
      .domain(yDomain)
      .range([height - marginBottom, marginTop]);

  // Create the SVG container.
  const svg = d3.select(svgContainer) 
      .attr("width", width)
      .attr("height", height)
      .attr("viewBox", [0, 0, width, height])
      .attr("style", "max-width: 100%; height: auto;");

      svg.selectAll("*").remove(); // Clear previous drawing

  // Add a rect for each bin.
  svg.append("g")
      .attr("fill", color)
    .selectAll("rect")
    .data(data)
    .join("rect")
      .attr("x", (d) => xScale(d.bin_start))
      .attr("width", (d) => xScale(d.bin_end) - xScale(d.bin_start))
      .attr("y", (d) => yScale(d.count))
      .attr("height", (d) => yScale(0) - yScale(d.count));

  // Add the x-axis and label.
  svg.append("g")
      .attr("transform", `translate(0,${height - marginBottom})`)
      .call(d3.axisBottom(xScale))
      .selectAll("text") // selects all text elements in the axis group
      .style("font-size", labelFontSize); // set the font size you want
      // labelFontSize;

  if (title) {
    svg.append("text")
        .attr("x", width - marginRight)
        .attr("y", height - 5)
        .attr("fill", "currentColor")
        .attr("text-anchor", "end")
        .text(title);
  }
  
  // Add the y-axis and label.
  svg.append("g")
      .attr("transform", `translate(${marginLeft},0)`)
      .call(d3.axisLeft(yScale))
      .append("text")
          .attr("x", -marginLeft)
          .attr("y", 10)
          .attr("fill", "currentColor")
          .attr("text-anchor", "start")
          .text("Count");

  // add chart title
  if (chartTitle) {
    svg.append("text")
      .attr("class", "chart-title") // For styling if needed
      .attr("x", width / 2) // Position at half the width of the SVG
      .attr("y", marginTop / 2) // Position in the middle of the top margin
      .attr("text-anchor", "middle") // Ensure the text is centered
      .text(chartTitle)
      .attr("font-size", 12.5)
  }

  // Optionally, you can add a label to the y-axis
  // svg.append("text")
  //     .attr("transform", "rotate(-90)")
  //     .attr("y", 0)
  //     .attr("x", 0 - (height / 2))
  //     .attr("dy", "1em")
  //     .attr("text-anchor", "middle")
  //     .text("Count")
  //     .attr("font-size", 12.5)

  // Return the SVG element.
  return svg.node();
}



export function BarChart(svgContainer,data, {
  x = (d, i) => i, // given d in data, returns the (ordinal) x-value
  y = d => d, // given d in data, returns the (quantitative) y-value
  chartTitle, // the title of the chart
  title, // given d in data, returns the title text
  marginTop = 20, // the top margin, in pixels
  marginRight = 10, // the right margin, in pixels
  marginBottom = 30, // the bottom margin, in pixels
  marginLeft = 20, // the left margin, in pixels
  width = 400, // the outer width of the chart, in pixels
  height = 100, // the outer height of the chart, in pixels
  xDomain, // an array of (ordinal) x-values
  xRange = [marginLeft, width - marginRight], // [left, right]
  yType = d3.scaleLinear, // y-scale type
  yDomain, // [ymin, ymax]
  yRange = [height - marginBottom, marginTop], // [bottom, top]
  xPadding = 0.1, // amount of x-range to reserve to separate bars
  yFormat, // a format specifier string for the y-axis
  yLabel, // a label for the y-axis
  color = "currentColor", // bar fill color,
  labelFontSize = 10
}) {
  // Compute values.
  const X = d3.map(data, x);
  const Y = d3.map(data, y);

  // Compute default domains, and unique the x-domain.
  if (xDomain === undefined) xDomain = X;
  if (yDomain === undefined) yDomain = [0, d3.max(Y)];
  xDomain = new d3.InternSet(xDomain);

  // Omit any data not present in the x-domain.
  const I = d3.range(X.length).filter(i => xDomain.has(X[i]));

  // Construct scales, axes, and formats.
  const xScale = d3.scaleBand(xDomain, xRange).padding(xPadding);
  const yScale = yType(yDomain, yRange);
  const xAxis = d3.axisBottom(xScale).tickSizeOuter(0);
  const yAxis = d3.axisLeft(yScale).ticks(height / 40, yFormat);

  // Compute titles.
  if (title === undefined) {
    const formatValue = yScale.tickFormat(100, yFormat);
    title = i => `${X[i]}\n${formatValue(Y[i])}`;
  } else {
    const O = d3.map(data, d => d);
    const T = title;
    title = i => T(O[i], i, data);
  }

//   const svg = d3.create("svg")
const svg = d3.select(svgContainer)

      .attr("width", width)
      .attr("height", height)
      .attr("viewBox", [0, 0, width, height])
      .attr("style", "max-width: 100%; height: auto; height: intrinsic;");

      svg.selectAll("*").remove(); // Clear previous drawing

  svg.append("g")
      .attr("transform", `translate(${marginLeft},0)`)
      .call(yAxis)
      .call(g => g.select(".domain").remove())
      .call(g => g.selectAll(".tick line").clone()
          .attr("x2", width - marginLeft - marginRight)
          .attr("stroke-opacity", 0.1))
      .call(g => g.append("text")
          .attr("x", -marginLeft)
          .attr("y", 10)
          .attr("fill", "currentColor")
          .attr("text-anchor", "start")
          .text(yLabel)
          );

  const bar = svg.append("g")
      .attr("fill", color)
    .selectAll("rect")
    .data(I)
    .join("rect")
      .attr("x", i => xScale(X[i]))
      .attr("y", i => yScale(Y[i]))
      .attr("height", i => yScale(0) - yScale(Y[i]))
      .attr("width", xScale.bandwidth());

  if (title) bar.append("title")
      .text(title);

      // add x-axis
  svg.append("g")
      .attr("transform", `translate(0,${height - marginBottom})`)
      .call(xAxis)
      .selectAll("text") // selects all text elements in the axis group
      .style("font-size", labelFontSize); // set the font size you want

  // add chart title
  if (chartTitle) {
    svg.append("text")
      .attr("class", "chart-title") // For styling if needed
      .attr("x", width / 2) // Position at half the width of the SVG
      .attr("y", marginTop / 2) // Position in the middle of the top margin
      .attr("text-anchor", "middle") // Ensure the text is centered
      .text(chartTitle)
      .attr("font-size", 12.5)
  }

  return svg.node();
}